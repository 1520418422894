import React from 'react';
import {Card, Col, Row} from "antd";
import '../join.css';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";

class AboutProject extends React.Component{
    render() {
        const {uz,en}=this.props;
        return (
            <div>
               <div className="about_main">
                   <img src="./images/join.jpg" alt=""/>
                   <div className="about_project-content"  data-aos={'zoom-in'}>
                     <p className="about-title">
                         {uz?"LOYIHA HAQIDA":"О ПРОЕКТЕ"}
                     </p>
                       <p className={'about_little-text'}>
                           {uz?"Innovatsiya, texnologiya va strategiya markazi tomonidan amalga oshirilayotgan “O‘zbekistonda mustaqil o‘qituvchilar assotsiatsiyalari imkoniyatlarini kengaytirish” loyihasi Yevropa Ittifoqi tomonidan moliyalashtirilayotgan bo‘lib, o‘qituvchilarning jamiyatdagi nufuzini oshirish, ijtimoiy himoyasini kuchaytirish, huquqiy savodxonligini oshirish, ularning kasbiy salohiyatini rivojlantirish maqsadida assosiasiyalar tuzilishi va faoliyat yuritishini qo‘llab-quvvatlaydi.":"Проект «Расширение возможностей независимых ассоциаций учителей в Узбекистане», реализуемый Центром инноваций, технологий и стратегии, финансируется Европейским Союзом и направлен на поддержку создания и функционирования независимых ассоциаций учителей в стране."}
                       </p>
                   </div>
               </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    AboutProject
)