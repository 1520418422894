import React from 'react';
import {
    Row,
    Col,
    Card,
} from 'antd';
import {BiSupport, FaHandsHelping, HiUserGroup, ImInfo, IoFileTrayFullSharp, RiUserStarLine} from "react-icons/all";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {Link} from "react-router-dom";

class Mission extends React.Component{

    render() {
        const {uz,en}=this.props;

        return(
            <>
                <div className="container ourmission-page">
                    <div className="page-title-content">

                        <Row>
                            <Col md={12} sm={24} xs={24}>
                              <p style={{color:'grey',fontSize:'22px',textAlign:'justify',padding:"10px"}}>
                                {
                                    uz?"O‘qituvchilar va assotsiatsiyalar uchun platforma “O‘zbekistonda Mustaqil o‘qituvchilar assotsiatsiyalari imkoniyatlarini kengaytirish” loyihasi doirasida yaratilgan. Internet-portalning maqsadi o‘qituvchilarning jamiyatdagi nufuzini oshirish, o‘qitish metodikasini takomillashtirish, huquqiy bilim va malakasini oshirish, dars o‘tish jarayonida zamonaviy axborot-kommunikatsiya texnologiyalarini joriy etishida ko‘maklashish, hamda musobaqalar, madaniy tadbirlarni tashkillashtirish va o‘tkazish sohasidagi chambarchas hamkorlik va tajriba almashinuvini amalga oshirishdan iborat.":"Платформа для учителей и ассоциаций создана в рамках проекта «Расширение возможностей независимых ассоциаций учителей в Узбекистане». Целью Интернет-портала является повышение статуса учителей в обществе, совершенствование методики преподавания, развитие юридических знаний и навыков, содействие во внедрении современных информационных и коммуникационных технологий в учебный процесс, а также оказание тесного сотрудничества и обмен опытом в организации и проведении конкурсов, культурных мероприятий."
                                }
                                </p>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <img style={{borderRadius:'0px',width:'100%'}} src="/images/about1.JPG" alt=""/>
                            </Col>
                        </Row>

                    </div>
                    <div className="container ourmission-page">
                        <div className="page-title-content">
                            <div className="title-content">
                                <p className="home-page-title">
                                    {uz?"Maqsadimiz":en?"":"наша миссия"}
                                </p>
                            </div>
                            <div className="title-content">
                                <span className="title-bottom-linear"></span>
                            </div>
                            <p className="mainColor little-text">
                                {uz?"Portalning asosiy maqsadi o‘qituvchilarni har tomonlama qo‘llab - quvvatlashdir":en?"":"Основной целью портала является оказание всесторонней поддержки учителям"}
                            </p>

                        </div>

                        <div className="card-page">
                            <Row>
                                <Col lg={12} md={12} sm={24} xs={24}>

                                    <div  data-aos="fade-right"  className="card-content">
                                        <div className={'icon-card'}>
                                      <span className="mission-icon">
                                          <RiUserStarLine style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span>
                                            <span className="icon-bottom-line"></span>
                                        </div>
                                        <div className={'text-card'}>
                                            <p className={'text-card-big-text'}>
                                                {uz?"O‘qituvchilar maqomini ko‘tarish":en?"":"Повышение статуса учителей"}

                                            </p>
                                            <p className={'text-card-little-text'}>
                                                {uz?"O‘qituvchilarning jamiyatdagi o‘rni va mavqeini oshirish":en?"":"Повышение роли и статуса учителя в обществе"}</p>
                                        </div>
                                    </div>

                                </Col>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <div className="card-content" data-aos={'fade-left'}>
                                        <div className={'icon-card'}>
                                         <span className="mission-icon">
                                          <HiUserGroup style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span>
                                            <span className="icon-bottom-line"></span>
                                        </div>
                                        <div className={'text-card'}>
                                            <p className={'text-card-big-text'} >
                                                {uz?"Tarmoqni rivojlantirish":en?"":"Развитие нетворкинга"}
                                            </p>
                                            <p className={'text-card-little-text'}>
                                                {uz?"O‘qituvchilarning o‘zaro aloqasi uchun platforma":en?"":"Площадка для общения и взаимодействия учителей"}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <div className="card-content" data-aos={'fade-right'}>
                                        <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <FaHandsHelping style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span> <span className="icon-bottom-line"></span>
                                        </div>
                                        <div className={'text-card'}>
                                            <p className={'text-card-big-text'}>
                                                {uz?"O‘qituvchilarni o‘zaro qo‘llab - quvvatlash":"Взаимоподдержка учителей"}

                                            </p>
                                            <p className={'text-card-little-text'}>
                                                {uz?"\"O‘qituvchidan o‘qituvchiga\" tamoyili bo‘yicha yordam berish":" Помощь по принципу «От учителя к учителю»"}

                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <div className="card-content" data-aos={'fade-left'}>
                                        <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <BiSupport style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span><span className="icon-bottom-line"></span>
                                        </div>
                                        <div className={'text-card'}>
                                            <p className={'text-card-big-text'}>

                                                {uz?"Konsultatsiya":"Консультация"}
                                            </p>
                                            <p className={'text-card-little-text'}>

                                                {uz?"O‘z vaqtida professional maslahat":"Своевременная профессиональная консультация"}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <div className="card-content" data-aos={'fade-right'}>
                                        <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <ImInfo style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span><span className="icon-bottom-line"></span>
                                        </div>
                                        <div className={'text-card'}>
                                            <p className={'text-card-big-text'}>

                                                {uz?"Keng imkoniyatlar":"Информирование о возможностях"}
                                            </p>
                                            <p className={'text-card-little-text'}>
                                                {uz?"Ta’lim sohasidagi tanlovlar, grantlar va ko‘rgazmalar haqida to‘liq ma’lumot":" Полная информация о конкурсах, грантах и ярмарках в сфере образования"}

                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <div className="card-content" data-aos={'fade-left'}>
                                        <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <IoFileTrayFullSharp style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span><span className="icon-bottom-line"></span>
                                        </div>
                                        <div className={'text-card'}>
                                            <p className={'text-card-big-text'}>

                                                {uz?"Resurslar bazasini yaratish":"Создание ресурсной базы"}
                                            </p>
                                            <p className={'text-card-little-text'}>
                                                {uz?"Elektron, video va uslubiy resurslarning keng ma’lumotlar bazasini yaratish":"Создание обширной базы электронных, видео и методических ресурсов"}

                                            </p>
                                        </div>
                                    </div>
                                </Col></Row>


                        </div>
                    </div>
                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Mission
)
