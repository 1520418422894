import React from 'react'
import {
    Row,
    Col,
    Card
} from 'antd';
import Map from "./Map";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
class Composition extends React.Component{
    render() {
        const {uz,en}=this.props;
        const places = [
            {
                name: "O'qituvchilar Assosiatsiyasi",
                title: "O'qituvchilar Assosiatsiyasi",
                lat: 41.322164,
                lng: 69.267404,
                id: 1
            },
            {
                name: "Sydney",
                title: "Sydney",
                lat: 41.362164,
                lng: 69.267404,
                id: 2
            },
            {
                name: "Melbourne",
                title: "Melbourne",
                lat: 41.322164,
                lng: 69.257404,
                id: 3
            },
            {
                name: "Perth",
                title: "Perth",
                lat: 41.354169,
                lng: 69.267409,
                id: 4
            }
        ];
        return(
            <>
                <h2>{uz?"Assotsiatsiyalar ro'yxati":"Список ассоциаций"}</h2>
                <Row>
                <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar  Assotsiatsiyasi":"Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4 style={{minHeight:'100px'}}>{uz?"Toshkent shahri, Yakkasaroy tumani, Mirobod ko‘chasi, 14-uy":"город Ташкент, Яккасарайский район, улица Мирабадская, 14"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 90 992 22 22</h4></Col>

                                </Row>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Toshkent shahri hududiy filiali":"Ташкентское областное отделение Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4 style={{minHeight:'100px'}}>{uz?"Toshkent shahri, Mirzo Ulug‘bek tumani, Qalandar ko‘chasi, 5-uy":"г. Ташкент, Мирзо-Улугбекский район, улица Каландар, 5"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 97 138 44 66</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Farg‘ona viloyati hududiy filiali":"Ферганское областное отделение Союза учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Farg’ona viloyati, Uchko‘pir tumani, Olchazor ko‘chasi, 123-uy":"Ферганская область, Учкопирский район, улица Олхазор, 123"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 90 343 94 35</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Jizzax viloyati hududiy filiali":"Джизакское областное отделение Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Jizzax viloyati, Forish tumani, Bog’iston ko‘chasi, 30-uy":"Джизакская область, Форишский район, улица Богистан, 30"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 99 457 43 80</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Namangan viloyati hududiy filiali":"Наманганский областной филиал Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Namangan viloyati, Uychi tumani, Amir Temur ko‘chasi, 36-uy":"Наманганская область, Уйчинский район, улица Амира Темура, 36"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+99894 170 63 50</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Navoiy viloyati hududiy filiali":"Навоийское областное отделение Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Navoiy viloyati, Navoiy shahri, Navoiy ko‘chasi, 30-uy":"Навоийская область, город Навои, улица Навои, 30"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 90 646 25 52</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Surxondaryo viloyati hududiy filiali":"Сурхандарьинское региональное отделение Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Surxondaryo viloyati, Termiz shahri, Navbahor ko‘chasi, 28-uy":"Сурхандарьинская область, г.Термез, ул.Навбахор, 28"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 90 295 06 07</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Andijon viloyati hududiy filiali":"Андижанское областное отделение Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Andijon viloyati, Izboskan tumani, Murabbiylar ko‘chasi, 14-uy":"Андижанская область, Избасканский район, улица Мураббайлар, 14"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 90 154 29 28</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Xorazm  viloyati hududiy filiali":"Хорезмский  областное отделение Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Xorazm viloyati, Urganch shahri, kichik massiv, 4-uy":"Хорезмская область, город Ургенч, малый массив, 4-дом"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 90 737 17 35</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>
                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Toshkent viloyati hududiy filiali":"Ташкентский областной филиал Ассоциации учителей"}
                                </p>
                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Toshkent viloyati, Angren shahri, 31-massiv, 7-uy":"Ташкентская область, город Ангрен, 31-массив, 7-дом"}</h4></Col>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 93 668 85 28</h4></Col>
                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>
                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Sirdaryo  viloyati hududiy filiali":"Сырдарьинский  областной филиал Ассоциации учителей"}
                                </p>
                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Sirdaryo viloyati, Guliston shahri, Xalqlar do‘stligi ko‘chasi, 30-uy":"Сырдарьинская область, город Гулистан, улица Дружбы народов, 30-дом"}</h4></Col>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 9- --- -- --</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Qashqadaryo   viloyati hududiy filiali":"Кашкадарьинский   областной филиал Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Qashqadaryo viloyati, Qarshi shahri, Olimlar ko‘chasi, 2-uy":"Кашкадарьинская область, город Карши, улица Олимлар, 2-дом"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 91 946 46 97</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Samarqand    viloyati hududiy filiali":"Самаркандский    областной филиал Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Samarqand viloyati, Samarqand shahri, Dagbit ko‘chasi, 170-uy":"Самаркандская область, город Самарканд, улица Дагбит, 170-уй"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 97 575 61 85</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                     <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?" O‘qituvchilar Assotsiatsiyasining Qoraqalpog'iston Respublikasi hududiy filiali":"Каракалпакский Республиканский региональный филиал Ассоциации Учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Qoraqalpog'iston Respublikas, Kegeyli tumani, Aktuba MFY":"Республика Каракалпакстан, Кегейлийский район, Актюбинский СГМ"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 93 488 21 03</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} md={12} sm={24}>
                        <Card style={{width:'90%',marginLeft:'5%',padding:'5px',margin:'5px',borderRadius:'20px'}}>
                            <div style={{textAlign:'center'}} data-aos={'fade-bottom'}>
                                <Row>
                                    <Col md={24} sm={24}>
                                        <img src='/images/TA_logo_horizontal.png' style={{width:'100%'}} alt=""/>
                                    </Col>

                                </Row>
                                <p style={{fontSize:'20px',fontWeight:'600'}}>
                                    {uz?"O‘qituvchilar Assotsiatsiyasining Buxoro viloyati hududiy filiali":"Бухарское областной филиал Ассоциации учителей"}
                                </p>

                                <Row>
                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Manzil:":"Адрес:"}</h4></Col>
                                    <Col span={12}><h4  style={{minHeight:'100px'}}>{uz?"Buxoro viloyati  Buxoro shahar Namozgoh ko‘chasi 29-uy":"Бухарская область, город Бухара, улица Намазгох, 29"}</h4></Col>

                                    <Col span={12}><h4 style={{color:'gray'}}>{uz?"Raqam:":"Номер:"}</h4></Col>
                                    <Col span={12}><h4>+998 93 063 16 16</h4></Col>

                                </Row>

                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"A'zo bo'lish":"Подписаться"}
                                    </button>
                                </div>
                                <div className="d-flex-center">
                                    <button className="button mission-btn">
                                        {uz?"Saytga o‘tish":"Перейти на сайт"}
                                    </button>
                                </div>
                            </div>
                        </Card>
                    </Col>

                </Row>
                <div className="d-flex-center">
                    <button className="button mission-btn mainColor" style={{backgroundColor:'white',border:'1px solid #099E8F',marginTop:'20px'}}>
                        {uz?"Ko'proq ko'rsatish":"Показать еще"}
                    </button>
                </div>
                <h2> {uz?"Ishtirokchi tashkilotlar (xaritada)":"Участвующие организации (на карте)"}</h2>
                <div className="img container" style={{marginTop:'100px'}}>
                    <Map
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key="
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        center={{ lat: 41.322164, lng: 69.267404 }}
                        zoom={14}
                        places={places}
                    />
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Composition
)
