import {
    TrophyOutlined,
    NotificationOutlined,
    FileOutlined,
    CommentOutlined} 
    from "@ant-design/icons"
export const MenuList = [
    {
        titleRu: 'КОНКУРСЫ',
        titleUz:'Tanlovlar',
        titleEn:'',
        link: '/contests',
        icon:<TrophyOutlined />,
    },{
        titleRu: 'НОВОСТИ',
        titleUz:'Yangiliklar ',
        titleEn: '',
        link: '/news',
        icon:<NotificationOutlined />,
    },{
        titleRu: 'РЕСУРСЫ',
        titleUz: 'Manbalar',
        titleEn: '',
        link: '/resources',
        icon:<FileOutlined />,
    },{
        titleRu: 'КОНСУЛЬТАЦИЯ',
        titleUz: 'Konsultatsiya',
        titleEn: '',
        link: '/consultations',
        icon: <CommentOutlined />,
    },

];