import React from 'react';
import './about.css';
import {
    Row,
    Col,
    Tabs
} from 'antd';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {FaDiscourse} from "react-icons/fa";
import Mission from "./mission/mission";
import Contact from "./contact/contact";
import Composition from "./composition/composition";
import Gallery from "./gallery/gallery";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
const {TabPane}=Tabs;
class About extends React.Component{
    state={
        key:''
    }
    componentDidMount() {
        this.setState({
            key:window.location.pathname.split('/')[2],
        });
    }

    render() {
        const {uz,en}=this.props;

        const {
            key,
        }=this.state;
        const callback=(key)=>{
            this.setState({
                key:key,
            })
        }
        return(
            <>
              <HorizontalMenu/>
                <div className="faq-page">
                    <div className="container">
                        <div className="title-page">
                            <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa ":"Главная"}</Link>   > <span className="mainColor">{uz?"Maqsadimiz":"Наша миссия"}</span></p> <br/>

                        </div>
                        <Tabs defaultActiveKey={window.location.pathname.split('/')[2]} onChange={callback}>
                            <TabPane tab={<h3 className={'tab-title title1'} style={{textAlign:'center'}}>{uz?"Maqsadimiz":"Наша миссия"}</h3>} key="our-mission">
                                <Mission/>
                            </TabPane>
                            <TabPane tab={<h3 className={'tab-title title1'} style={{textAlign:'center'}}>{uz?"Galereya":"Галерея"}</h3>} key="gallery">
                             <Gallery/>
                            </TabPane>
                            <TabPane tab={<h3 className={'tab-title title1'} style={{textAlign:'center'}}>  {uz?"Assotsiatsiyalar":"Ассоциации"}</h3>} key="compositions">
                            <Composition/>
                            </TabPane>
                            <TabPane tab={<h3 className={'tab-title title1'} style={{textAlign:'center'}}> {uz?"Biz bilan bog’lanish":"Контакты"}</h3>} key="contact">
                               <Contact/>
                            </TabPane>


                        </Tabs>
                    </div>





                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    About
)
