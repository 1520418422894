import React from 'react';
import './resources.css';
import {
    Row,
    Col,
} from 'antd';
import '../../styles/global.css';
import {
    FaBars,
    FaTh,
}from 'react-icons/fa';
import { Tabs } from 'antd';
import Video_lessons from "./video_lessons/video_lessons";
import Aids from "./aids/aids";
import Links from "./links/links";
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import Line_Video_lessons from "./video_lessons/line_video_lessons";
import Line_Aids from "./aids/line_aids";
import Line_Links from "./links/line_links";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getUsefulLinks} from "../../api/config/Servises";
const { TabPane } = Tabs;

class Resources extends React.Component {
    state= {
        line: false,
    }

    render() {
        const {uz,en}=this.props;
        const {line,links,size}=this.state;
        const Table_Resources=()=>{
            this.setState({
                line:false,
            })
            document.querySelector('.table-icon').classList.remove('before_select')
            document.querySelector('.line-icon').classList.add('before_select')

        };
        const Line_Resources=()=>{
            this.setState({
                line:true,
            })
            document.querySelector('.line-icon').classList.remove('before_select')
            document.querySelector('.table-icon').classList.add('before_select')

        };


        return (
            <>
                <HorizontalMenu/>
                <div className="faq-page">
                    <div className="container">
                        <div className="title-page">
                            <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>    > <span className="mainColor">{uz?"Manbalar":"Ресурсы"}</span></p> <br/>

                        </div>
                        <div className="title-page">
                            <p className="big-title-route">

                                {uz?"Manbalar":"Ресурсы"}
                            </p>
                            <p className="calendar-icon">
                                <FaBars onClick={Line_Resources} className={'line-icon before_select'}/>
                                <FaTh onClick={Table_Resources} className={'table-icon'}/>
                            </p>
                        </div>
                        <Tabs  className={"tabs-panel"} defaultActiveKey="1">
                            <TabPane   tab={<h3 className={'tab-title '}>{uz?"Video-darsliklar":"Видео-уроки"}</h3>} key="1">
                                {
                                    line?<Line_Video_lessons/>:<Video_lessons/>
                                }
                            </TabPane>
                            <TabPane tab={<h3 className={'tab-title'}>{uz?"Metodik qo‘llanmalar":"Методические пособия"}</h3>} >
                                {
                                    line?<Line_Aids/>:<Aids/>
                                }

                            </TabPane>
                            <TabPane tab={<h3 className={'tab-title'}>{uz?"Foydali havolalar":"Полезные ссылки"}</h3>} key="3">
                                {
                                    line?<Line_Links/>:<Links/>
                                }

                            </TabPane>

                        </Tabs>
                    </div>


                </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Resources
)