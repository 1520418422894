import React from 'react';
import './consultationCarousel.css';
import '../../../../styles/global.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Row,
    Col,
    Card,
} from 'antd';
import {connect} from "react-redux";
import {uzLanguage} from "../../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../../redux/actions/EnLanguage";
import {Link} from "react-router-dom";
import {host, port} from "../../../../api/host";
import {getConsultants} from "../../../../api/config/Servises";
class ConsultationCarousel extends React.Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state={
            consultants:[],
        }
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    componentDidMount() {
        getConsultants(0,6).then(res=>{
            if(res&&res.data){
                this.setState({
                    consultants:res.data,
                })
            }
        }).catch(error=>{
            console.log(error)
        })
    }

    render() {
        const {uz,en}=this.props;
        const {consultants}=this.state;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:true,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true,
                        autoplay:true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2,
                        autoplay:true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay:true,
                    }
                }
            ],

        };
        return(
            <>
                <div className={'consultation-page '}>
                    <div className="big-post p5 container">
                        <div className="page-title-content">
                            <div className="title-content">
                                <p className="home-page-title big-title-1">

                                    {uz?"O‘z vaqtida konsultatsiya":"Своевременные консультации"}
                                </p>
                            </div>
                            <div className="title-content">
                                <span className="title-bottom-linear"></span>
                            </div>
                            <div className="d-flex">
                                <p className="mainColor little-text">
                                    {uz?"Bizning yuqori malakali mutaxassislarimizdan malakali maslahat oling ":"Получите грамотную консультацию у наших высококвалифицированных специалистов"} </p>
                            </div>
                        </div>

                        <Slider {...settings} autoplay={true}>
                            {
                                consultants&&consultants.map(item=>{
                                    return <div className={'slider-item'}>
                                        <Row>
                                            <Col md={24} sm={24} xs={24}>
                                                <div className="consultation-carousel">
                                                    <Card className={"consultation-carousel-card"}>
                                                        <div className="avatar-and-tags">
                                                            <Row>
                                                                <Col span={24}>
                                                                    <div className="img1">
                                                                        <img src={host+':'+port+'/api/auth/file/'+item.avatar.id} alt=""/>
                                                                    </div>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <p className="consultation-title">
                                                                        {uz?item.fullnameUz:item.fullnameRu}
                                                                    </p>
                                                                    {/*<p className="w100 fs-fw" style={{color:'grey'}}>*/}
                                                                    {/*    {uz?"#mavzu #o‘zbek tili #adabiyot":"#предмет #узбекскийязык #литература "}*/}
                                                                    {/*</p>*/}
                                                                </Col>
                                                                <Col span={24}>
                                                                    <div className="p10 consultation-carousel-little-text">
                                                                        {uz?item.aboutUz:item.aboutRu}

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                        <Link to={'/consultations/'+item.id}>
                                                            <div className="d-flex-center">
                                                                <button className="button consultation-btn ">
                                                                    {uz?"Konsultatsiya olish":"Получить консультацию"}
                                                                </button>
                                                            </div>
                                                        </Link>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                })
                            }

                        </Slider>

                    </div>
                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ConsultationCarousel
)