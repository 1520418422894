import React from 'react';
import './gallery.css';
import {
    Row,
    Col,
} from 'antd';
import Slider from "react-slick";
import {
    AiFillPlayCircle
} from 'react-icons/all';
import HorizontalMenu from "../../../commonComponents/horizontalMenu/horizontalMenu";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {getGalleryById} from "../../../api/config/Servises";
import {host, port} from "../../../api/host";
import Loader from "../../../commonComponents/loader/loader";
class Gallery_by_id extends React.Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    state={
        img_url:'',
        video_url:'',
        gallery:{},
        loading:false,
        video_show:0,
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    componentDidMount() {
        getGalleryById(window.location.pathname.split('/')[2]).then((res)=>{
            console.log(res);
            if(res&&res.data){
                this.setState({
                    gallery:res.data,
                    img_url:res.data.images?res.data.images[0].id:"",
                    video_url:res.data.videos?res.data.videos[0].id:"",
                    loading:false,
                    video_show:res.data.videos.length>2?3:1,
                })
                console.log(res.data);
                
            }
        }).catch(res=>{
            console.log('gallery-error');
        })
    }

    render() {
        const {uz,en}=this.props;
        const {gallery,video_show}=this.state;
        
        const {
            img_url,
            video_url
        }=this.state;
        const Open_Img=(url)=>{
            this.setState({
                img_url: url,
            })
        }
        const Open_Video=(url)=>{
            this.setState({
                video_url: url,
            })
        }
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:true,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ],
            // customPaging: bb => (
            //     <div
            //         onMouseDown={this.bb}
            //         className="consultation-dots"
            //
            //     >
            //     </div>
            // )
        };
        var settings1 = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:true,
            slidesToShow: video_show,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ],
   
        };
        return (
           <div  style={{marginTop:'-13px',overflowX:'hidden'}}> <HorizontalMenu />
               {
                   this.state.loading?<Loader/>:<div>

                       <div className={'container gallery-by-id p10'} style={{}}>
                           <p className="big-title-route" style={{width:'35%',fontSize:'30px'}}>
                               {
                                   gallery?uz?gallery.titleUz:gallery.titleRu:''
                               }
                               <br/>
                               <p style={{color:'grey',fontSize:'16px'}}>
                                   {/*Сентябрь-декабрь 2021 года*/}
                               </p>
                           </p>
                           <h1>{uz?"Rasm":"Фото"}</h1>
                           <div className="big-images">
                               <img src={host+':'+port+'/api/auth/file/'+img_url} alt=""/>
                           </div>
                           <Slider {...settings} style={{marginBottom:'50px',marginLeft:'5%'}}>
                               {
                                   gallery.images?gallery.images.map(item=>{
                                       console.log(item.id);
                                       return <div className="img-item" onClick={()=>Open_Img(item.id)} >
                                           <img src={host+':'+port+'/api/auth/file/'+item.id} alt=""/>
                                       </div>
                                   }):""
                               }


                           </Slider>
                           <h1>{uz?"Video":"Видео"}</h1>
                           <div className="big-images">
                               <video style={{width:'80%',marginLeft:'5%',borderRadius:"10px"}} src={host+':'+port+'/api/auth/file/'+video_url} controls  ></video>


                           </div>
                           <Slider {...settings1} style={{marginBottom:'50px'}}>

                               {
                                   gallery.videos?gallery.videos.map(item=>{
                                       return <div className="img-item p10" onClick={()=>Open_Video(item.id)} >
                                           <video style={{width:'80%',borderRadius:'10px',marginLeft:'10%'}} src={host+':'+port+'/api/auth/file/'+item.id} />
                                           <div className="little-icon">
                                               <AiFillPlayCircle  onClick={()=>Open_Video(item.id)}/>
                                           </div>
                                       </div>
                                   }):""
                               }



                           </Slider>
                       </div>
                   </div>
               }
           </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Gallery_by_id
)
