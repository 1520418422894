export const month=[
    {
        id:1,
        titleUz:'Yanvar',
        titleRu:'Январь'
    },
    {
        id:2,
        titleUz:'Fevral',
        titleRu:'Февраль'
    },
    {
        id:3,
        titleUz:'Mart',
        titleRu:'Март'
    },
    {
        id:4,
        titleUz:'Aprel',
        titleRu:'Aпрель'
    },
    {
        id:5,
        titleUz:'May',
        titleRu:'Май'
    },
    {
        id:6,
        titleUz:'Iyun',
        titleRu:'июнь'
    },
    {
        id:7,
        titleUz:'Iyul',
        titleRu:'июль'
    },
    {
        id:8,
        titleUz:'Avgust',
        titleRu:'август'
    },
    {
        id:9,
        titleUz:'Sentabr',
        titleRu:'сентябрь'
    },
    {
        id:10,
        titleUz:'Oktabr',
        titleRu:'Октябрь'
    },
    {
        id:11,
        titleUz:'Noyabr',
        titleRu:'Ноябрь'
    },
    {
        id:12,
        titleUz:'Dekabr',
        titleRu:'Декабрь'
    },
]