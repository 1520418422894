import React from 'react';
import './contests.css';
import {
    Row,
    Col,
} from 'antd';
import {
    Link
} from 'react-router-dom';
import '../../styles/global.css';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getContestyId} from "../../api/config/Servises";
import {host, port} from "../../api/host";
import Loader from "../../commonComponents/loader/loader";
class Contest_by_id extends React.Component {
    state={
        contest:{},
        img_id:'',
        loading:true,
    }
    componentDidMount() {
        getContestyId(window.location.pathname.split('/')[2]).then(res=>{
            if(res&&res.data){
                this.setState({
                    contest:res.data,
                    img_id:res.data.logo.id,
                    loading:false,
                })
            }
        }).catch(res=>{
            console.log('error')
        })
    }

    render() {
        const {uz,en}=this.props;
        const {contest,img_id}=this.state;
        return (
            <>
                <HorizontalMenu/>
                {
                    this.state.loading?<Loader/>:  <div className="faq-page">
                        <div className="container">
                            <div className="title-page">
                                <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>    > <span className="mainColor">{uz?"Tanlovlar":"Конкурсы"}</span></p> <br/>

                            </div>
                            <p className={'title-contest'}> {
                                contest?uz?contest.titleUz:contest.titleRu:""
                            }
                            </p>
                            <Row>
                                <Col md={8} sm={16}>
                                    <p className="w100 fs-fw" style={{color:'grey'}}>
                                        {uz?"Ro'yxatdan o'tish muddati":" Pегистрация до"}: {
                                        contest? new Date(contest.registrationAt).getDate()+'.'+new Date(contest.registrationAt).getMonth()+'.'+new Date(contest.registrationAt).getFullYear():""
                                    }
                                    </p>
                                </Col>
                                <Col md={8} sm={16}>
                                    <p className="w100 fs-fw" style={{color:'grey'}}>
                                        {uz?"Boshlanish vaqti":" Hачало в"}: {
                                        contest? new Date(contest.startAt).getDate()+'.'+new Date(contest.startAt).getMonth()+'.'+new Date(contest.startAt).getFullYear():""
                                    }
                                    </p>
                                </Col>
                            </Row>
                            <div className="d-flex-center">
                                <img src={host+':'+port+'/api/auth/file/'+img_id} alt="" className="w75"/>
                            </div>
                            <p className="w100" style={{fontSize:'24px'}} dangerouslySetInnerHTML={{__html:contest?uz?contest.contentUz:contest.contentRu:""}}/>
                        </div>


                    </div>
                }
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Contest_by_id
)