import React from 'react';
import './sidebarMenu.css';
import {
    Row,
    Col,
    Drawer,
    Menu,
    Dropdown, Select,
} from 'antd';
import {
    Link, Redirect
} from "react-router-dom";
import {
    FaUser,
    FaWindowClose,
    
} from 'react-icons/fa';
import {MenuList} from "../../constants/menuList";
import HorizontalMenu from "../horizontalMenu/horizontalMenu";
import {
    DownOutlined,
    FileProtectOutlined,
    UsergroupAddOutlined,
    AreaChartOutlined,
    FormOutlined,
    FileImageOutlined,
    TeamOutlined,
    PhoneOutlined,
    CommentOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import HomePage from "../../pages/home/home";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import ModalSignin from "../horizontalMenu/components/ModalSignin";
import ModalSignup from "../horizontalMenu/components/ModalSignup";

class SidebarMenu extends React.Component{
    state={
        visible:true,
        signupModal:false,
    };
    openSignUp=()=>{
        this.setState({
            signupModal:true
        })
    }
    render() {
        const {visible}=this.state;
        const {uz,en}=this.props;
        const CloseDrawer = () => {
            this.setState({
                visible:false,
            });
        };
        const about_proect = (
            <Menu className={'menu-dropdown'} style={{marginTop:0}}>
                <Menu.Item>

                    <Link to={'/about/our-mission'} ><p style={{fontWeight:'500',fontSize:'16px'}}> 
                    <Row>
                        <Col span={3}><FormOutlined /></Col>
                        <Col span={21}>{uz?"Maqsadimiz":en?"":"НАША МИССИЯ"}</Col>
                    </Row>
                    </p>
                    </Link>


                </Menu.Item>
                <Menu.Item  >
                    <Link to={'/about/gallery'}><p  style={{fontWeight:'500',fontSize:'16px'}}>
                        
                       
                        <Row>
                        <Col span={3}><FileImageOutlined /></Col>
                        <Col span={21}> {uz?"Galereya":en?"":"ГАЛЕРЕЯ"}</Col>
                    </Row>
                        </p></Link>

                </Menu.Item>
                <Menu.Item>

                    <Link to={'/about/compositions'}><p  style={{fontWeight:'500',fontSize:'16px'}}>
                    
                        <Row>
                        <Col span={3}><TeamOutlined /></Col>
                        <Col span={21}>  {uz?"Bizning jamoa":en?"":"НАШ СОСТАВ"}</Col>
                    </Row>
                        </p></Link>



                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/about/contact'}> <p  style={{fontWeight:'500',fontSize:'16px'}}>
                     <Row>
                        <Col span={3}><PhoneOutlined /></Col>
                        <Col span={21}>   {uz?"Biz bilan bog’lanish":en?"":"КОНТАКТЫ"}</Col>
                    </Row>
                        </p></Link>

                </Menu.Item>
            </Menu>
        );
        const forum_list = (
            <Menu className={'menu-dropdown'}  style={{marginTop:0}}>
                <Menu.Item>

                    <Link to={'/forum'} ><p style={{fontWeight:'500',fontSize:'16px'}}>
                        <Row>
                        <Col span={3}><CommentOutlined /></Col>
                        <Col span={21}>   {uz?"FORUM":en?"":"ФОРУМ"}</Col>
                    </Row>
                        </p></Link>


                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/faq'}><p  style={{fontWeight:'500',fontSize:'16px'}}>
                   
                    <Row>
                        <Col span={3}> <QuestionCircleOutlined /></Col>
                        <Col span={21}>  FAQ</Col>
                    </Row>
                        </p></Link>

                </Menu.Item>

            </Menu>
        );

        const  onClose = () => {
            this.setState({
                visible: false,
            })
        };
        const changeSelect=(value)=>{
            if (value==="UZ"){
                this.props.uzLanguage();
            }else if (value==="EN") {
                this.props.enLanguage();
            }else {
                this.props.ruLanguage();
            }
            window.location.pathname='/home'
        };
        return (
            <div style={{marginTop:"-15px",padding:0}}>
                <HomePage/>
                <Drawer
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={visible}
                    maskClosable={false}
                >

                    <ul className="sidebar_ul">
                        <li>
                           <Link to={'/home'} style={{color:'white'}}>
                            <FaWindowClose/>
                            </Link>
                        </li>

                        <li>
                            <Dropdown trigger='click' overlay={about_proect}>
                                <Row>
                                    <Col span={3}><FileProtectOutlined /></Col>
                                    <Col span={21}><p className="w100"> {uz?"LOYIHA HAQIDA":"O ПРОЕКТE"}<DownOutlined /></p>
                                      </Col>
                                </Row>
                                  </Dropdown>
                        </li>
                       <li>
                           <Dropdown trigger='click' overlay={forum_list}>
                           <Row>
                                    <Col span={3}><UsergroupAddOutlined /></Col>
                                    <Col span={21}> <p className="w100">{uz?"FORUM":"ФОРУМ"} <DownOutlined /></p>
                                      </Col>
                                </Row>
                           
                              
                           </Dropdown>
                       </li>

                        {
                            MenuList.map(item=>{
                                return                <li>
                                <Row>
                                            <Col span={3}>{item.icon}</Col>
                                            <Col span={21}> <Link to={item.link}><p className="w100 menu-items menu-items" >{uz?item.titleUz:en?"":item.titleRu}</p></Link>
                                </Col>
                                        </Row>
                                   
                                
                               </li>
                            })
                        }
                        
                        <li>
                        <Row>
                                    <Col span={3}><AreaChartOutlined /></Col>
                                    <Col span={21}> <Link to={'/join'}><p className="w100 menu-items menu-items" >{uz?"Assotsiatsiya tuzish":en?"":"Cоздать ассоциацию"}</p></Link>
                        </Col>
                                </Row>
                           
                        
                       </li>
                        <li>
                            <Select defaultValue={uz?"UZ":"RU"} style={{color:'#009E8F'}} onChange={changeSelect}>
                                <Select.Option style={{color:'#009E8F'}} value={'UZ'}>UZ</Select.Option>
                                <Select.Option style={{color:'#009E8F'}} value={'RU'}>RU</Select.Option>
                            </Select>
                        </li>
                        <li style={{marginLeft:'-50px'}}>
                          <ModalSignin openSignUp={this.openSignUp}/>
                            {/*<FaUser/>*/}
                        </li>
                    </ul>
                </Drawer>
                {
                    this.state.signupModal?<ModalSignup/>:""
                }
            </div>
        );
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    SidebarMenu
)