import React, {Component} from 'react';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {Link, Redirect} from "react-router-dom";
import {Card, Col, message, Row, Upload} from "antd";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {ImAttachment} from 'react-icons/im'
import {postConsultation} from "../../api/config/Servises";
class ConsultationById extends Component {
    state={
        consultantId:null,
    }

    componentDidMount() {
        this.setState({
            consultantId:window.location.pathname.split('/')[2],
        })
    }

    render() {
        const {consultantId}=this.state;
        const {uz}=this.props;
        const PostConsultation=()=>{
            const consultation={
                "consultantId": consultantId,
                "email": document.querySelector('.email-value').value,
                "fullname": document.querySelector('.fullname-value').value,
                "message": document.querySelector('.message-value').value,
                "phoneNumber": document.querySelector('.phone-value').value,
                "theme": document.querySelector('.theme-value').value
            }
            console.log(consultation)
            postConsultation(consultation).then(res=>{
                if (res){
                    message.success(uz?"Konsultatsiya yuborildi!":"Консультация отправлена!")
                    document.querySelector('.email-value').value=''
                    document.querySelector('.fullname-value').value=''
                    document.querySelector('.message-value').value=''
                    document.querySelector('.phone-value').value=''
                    document.querySelector('.theme-value').value=''
                    window.location.pathname='/consultations'

                }
            }).catch(error=>{
                console.log(error)
            })
        }
        return (
            <div>
                <HorizontalMenu/>
                <div className="faq-page">

                    <div className="container">
                        <div className="title-page">
                            <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>   > <span className="mainColor">{uz?"Konsultatsiya":"Консультация"}</span></p> <br/>

                        </div>
                        <div className="title-page">
                            <p className="big-title-route">
                                {uz?"Konsultatsiya":"Консультация"}
                            </p>
                        </div>
                       <div className="w100 p10">
                           <Row>
                               <Col span={24} className={'p10'}>
                                   <div className="title-area-forum consultation-input-area p10">
                                       <input type="text" placeholder={uz?"F.I.Sh":'Ф.И.О'} className="consultation-input fullname-value"/>
                                   </div>
                               </Col>
                               <Col md={9} sm={24} xs={24} span={24} className={'p10'}>
                                   <div className="title-area-forum consultation-input-area p10">
                                       <input type="number" placeholder={uz?"Telefon":'Телефон'} className="consultation-input phone-value"/>
                                   </div>
                               </Col>
                               <Col span={2}></Col>
                               <Col md={9} sm={24} xs={24} span={24} className={'p10'}>
                                   <div className="title-area-forum consultation-input-area p10">
                                       <input type="email"  placeholder={uz?"Elektron manzil":'Электронная почта'} className="consultation-input email-value"/>
                                   </div>
                               </Col>
                               <Col span={24} className={'p10'}>
                                   <div className="title-area-forum consultation-input-area p10">
                                       <input
                                           type="text"
                                           placeholder={uz?"Mavzu":'Тема'}
                                           className="consultation-input theme-value"/>
                                   </div>
                               </Col>
                               <Col span={24} className={'p10'}>
                                   <div style={{minHeight:'150px'}} className="title-area-forum consultation-input-area p10 consultation-textarea">
                                       <textarea
                                           placeholder={uz?"Matn":"Tекст"}
                                           className="consultation-input consultation-textarea message-value"/>
                                   </div>
                               </Col>

                               <Col md={9} sm={24} xs={24} span={24} className={'p10'}>
                                   <div className="title-area-forum consultation-input-area p10">
                                       <Row>
                                           <Col span={3}>
                                               <Upload>
                                                   <ImAttachment style={{cursor:"pointer"}}/>
                                               </Upload>
                                           </Col>
                                           <Col span={21}>
                                               <span className="mainColor">{uz?"Biriktiring":"Прикрепить"}</span>
                                           </Col>
                                       </Row>

                                   </div>
                               </Col>
                           </Row>
                           <div className="d-flex-center"> <Link>
                               <button
                                   className="button mission-btn news-btn"
                                   style={{marginTop:'30%',width:'100%'}}
                                   onClick={PostConsultation}
                               >
                                   {uz?"Konsultatsiya olish":"Получить консультацию"}
                               </button></Link>

                           </div>

                       </div>


                    </div>


                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ConsultationById
)