import React from 'react';
import './news.css';
import '../../../../styles/global.css'
import {
    Row,
    Col,
    Card,
} from 'antd';
import {
    Link
} from 'react-router-dom';
import {
    FaCalendarAlt,
    FaArrowRight
} from 'react-icons/fa';
import {connect} from "react-redux";
import {uzLanguage} from "../../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../../redux/actions/EnLanguage";
import {getNews} from "../../../../api/config/Servises";
import {host, port} from "../../../../api/host";
import {month} from "../../../../constants/month";

class News extends React.Component{
    state={
        news:[],
    }

    componentDidMount() {
        getNews(0,3).then(res=>{
            if (res && res.data&&res.data.content){
                this.setState({
                    news:res.data.content,
                })


            }
        }).catch(res=>{
            console.log('error')
        })


    }

    render() {
        const {
            news
        }=this.state;
        const {uz,en}=this.props;
        return(
            <>
                <div className="news-page">
                   <div className=" container">
                       <div className="page-title-content">
                           <div className="title-content">
                               <p className="home-page-title">

                                   {uz?"Ta’lim Yangiliklari":"Новости образования"}
                               </p>
                           </div>
                           <div className="title-content">
                               <span className="title-bottom-linear"></span>
                           </div>
                          <div className="d-flex">
                              <p className="mainColor little-text">{uz?"Ta’lim sohasiga bevosita aloqador so‘nggi yangiliklardan xabardor bo‘ling":"Будьте в курсе самых свежих новостей, касающихся непосредственно сферы образования"}</p>
                          </div>
                       </div>

                       <div className="card-page">
                           <Row>
                               {
                                   news?news.map(function(item){
                                     var date=new Date(item.createAt)
                                     return <Col lg={8} md={12} sm={24} xs={24}>
                                         <div className={'news-card'} data-aos={'zoom-in'}>
                                             <div className="img-div">
                                                 <img src={host+':'+port+'/api/auth/file/'+item.logo.id} alt="" className="w100"/>
                                                 <Link to={'/news/'+item.id}> <div className="gallery-icon">
                                                     <p className={'mainColor'} style={{cursor:'pointer',paddingTop:'30%',fontSize:'20px'}}><FaArrowRight/></p>
                                                 </div></Link>
                                             </div>
                                             <div className="d-flex ">

                                                 <p className={"news-card-big-title"}  style={{color:'black'}}>
                                                     {uz?item.titleUz:item.titleRu}

                                                 </p>

                                                 <p className={"news-card-little-text"} dangerouslySetInnerHTML={{
                                                     __html: uz?item.contentUz:item.contentRu
                                                 }}/>


                                             </div>
                                             <div className="news-card-footer">
                                                 <div className="news-card-footer-content">
                                                     <div>
                                                         <p className="calendar-icon">
                                                             <FaCalendarAlt/>
                                                         </p>
                                                     </div>
                                                     <div className={"news-card-data"}>
                                                         {date.getDate()}-{uz?month[date.getMonth()].titleUz:month[date.getMonth()].titleRu} {date.getFullYear()}-{uz?"yil":"год"}
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </Col>
                                   }):""
                               }


                           </Row>
                           <div className="d-flex-center">
                              <Link to={'/news'}>
                                  <button className="button mission-btn news-btn">

                                      {uz?"Barcha yangiliklar":"Посмотреть все"}
                                  </button>
                              </Link>

                           </div>
                       </div>
                   </div>
                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    News
)
