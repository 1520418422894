import React from 'react';
import './faq.css';
import {
    Row,
    Col,
} from 'antd';
import '../../styles/global.css';
import {
    FaDiscourse,
}from 'react-icons/fa';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";

class Faq extends React.Component {

    render() {
        const {uz,en}=this.props;
        const Show_answer=(class1,class2,class3,class4)=>{

            document.querySelector('.answer-1').classList.add('close');
            document.querySelector('.answer-2').classList.add('close');
            document.querySelector('.answer-3').classList.add('close');
            document.querySelector('.answer-4').classList.add('close');
            document.querySelector('.answer-5').classList.add('close');
            document.querySelector('.lenta-1').classList.add('before-active');
            document.querySelector('.discuss-1').classList.add('discuss-color-before');
            document.querySelector('.question-1').classList.add('before-active');
            document.querySelector('.lenta-2').classList.add('before-active');
            document.querySelector('.discuss-2').classList.add('discuss-color-before');
            document.querySelector('.question-2').classList.add('before-active');
            document.querySelector('.lenta-3').classList.add('before-active');
            document.querySelector('.discuss-3').classList.add('discuss-color-before');
            document.querySelector('.question-3').classList.add('before-active');
            document.querySelector('.lenta-4').classList.add('before-active');
            document.querySelector('.discuss-4').classList.add('discuss-color-before');
            document.querySelector('.question-4').classList.add('before-active');
            document.querySelector('.lenta-5').classList.add('before-active');
            document.querySelector('.discuss-5').classList.add('discuss-color-before');
            document.querySelector('.question-5').classList.add('before-active');
            document.querySelector(`.${class2}`).classList.remove('before-active');
            document.querySelector(`.${class3}`).classList.remove('discuss-color-before');
            document.querySelector(`.${class4}`).classList.remove('before-active');
            document.querySelector(`.${class1}`).classList.remove('close');
        };
        return (
            <>
                <HorizontalMenu/>
                <div className="faq-page">
                    <div className="container">
                        <div className="title-page">
                            <p className="title-route">{uz?"Bosh sahifa ":"Главная"} > <span className="mainColor">FAQ</span></p> <br/>

                        </div>
                        <p className="big-title-route" >
                           FAQ
                        </p>
                    </div>
                   <div className="questions-page">
                       <div className="page-title-lenta lenta-1 ">
                           <div className="container">
                               <Row>
                                   <Col span={2}>
                                       <div className="discuss discuss-1 ">
                                           <FaDiscourse/>
                                       </div>
                                   </Col>
                                   <Col span={22}>
                                       <div className="question question-1" onClick={()=>Show_answer('answer-1','lenta-1','discuss-1','question-1')}>
                                           {uz?"Qanday qilib mustaqil o‘qituvchilar assotsiatsiyasini tuzishim mumkin?":"Как я могу создать независимую ассоциацию учителей?"}</div>
                                   </Col>
                               </Row>
                           </div>
                       </div>
                       <div className="container answer-1 " style={{marginBottom:'20px'}}>
                           <h2>
                               {uz?"Mustaqil o‘qituvchilar assotsiatsiyasini tuzish uchun siz kamida 10 ta hamfikr hamkasblaringizni to‘plashingiz, tuzilayotgan assotsiatsiyaning Ustavini tuzishingiz va zarur hujjatlar ilova qilingan arizani Adliya vazirligining hududiy boshqarmasiga topshirishingiz kerak. Hujjatlar 1 oygacha ko‘rib chiqiladi va mustaqil nodavlat notijorat tashkilotini ro‘yxatdan o‘tkazish to‘g‘risidagi guvohnoma beriladi.":"Для создания независимой ассоциации учителей Вам необходимо собрать не менее 10 единомышленников, составить Устав создаваемой ассоциации и с необходимыми документами подать заявку в территориальное управление Министерства Юстиции РУз. Документа рассматриваются до 1 месяца и, при соответствии документов, выдаётся свидетельство о регистрации независимой негосударственной некоммерческой организации"}
                           </h2>
                       </div>
                   </div>

                    <div className="questions-page">
                        <div className="page-title-lenta lenta-2 before-active">
                            <div className="container">
                                <Row>
                                    <Col span={2}>
                                        <div className="discuss discuss-2 discuss-color-before">
                                            <FaDiscourse/>
                                        </div>
                                    </Col>
                                    <Col span={22}>
                                        <div className="question question-2 before-active  " onClick={()=>Show_answer('answer-2','lenta-2','discuss-2','question-2')}>
                                            {uz?"Mustaqil birlashma tuzishda o'qituvchi uchun qanday imkoniyatlar ochiladi?":"Какие возможности открываются для учителя при создании независимой ассоциации?"}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="container   answer-2 close" style={{marginBottom:'20px'}}>
                            <h2>
                                {uz?<div>
                                    O‘qituvchilar uchun quyidagi imkoniyatlar ochiladi: <br/>
                                    - o‘qituvchilarning jamiyatdagi obro‘sini oshirish va ularni ijtimoiy himoya qilish;<br/>
                                    - o‘qituvchilarning huquqiy savodxonligini oshirish;<br/>
                                    - o‘qituvchilarning malakasi va kasbiy salohiyatini oshirish;<br/>
                                    - yosh o‘qituvchilarni qo‘llab-quvvatlash (an’anaviy tarzda ustoz-shogird); <br/>
                                    - o‘qituvchilarning innovatsion g’oyalarini ilgari surish.

                                </div>:<div>Для учителей открываются следующие возможности: <br/>
                                    - повышение авторитета учителей в обществе и их социальная защита;<br/>
                                    - повышение правовой грамотности учителей;<br/>
                                    - повышение квалификации и профессионального потенциала учителей;<br/>
                                    - поддержка молодых учителей (по традиции наставник-ученик);<br/>
                                    - продвижение инновационных идей учителей.<br/></div>}
                            </h2>
                        </div>
                    </div>


                    <div className="questions-page">
                        <div className="page-title-lenta lenta-3 before-active">
                            <div className="container">
                                <Row>
                                    <Col span={2}>
                                        <div className="discuss discuss-3 discuss-color-before">
                                            <FaDiscourse/>
                                        </div>
                                    </Col>
                                    <Col span={22}>
                                        <div className="question question-3 before-active  " onClick={()=>Show_answer('answer-3','lenta-3','discuss-3','question-3')}>

                                            {uz?"O‘zbekistondagi nodavlat notijorat tashkilotlari faoliyatini qaysi qonun hujjati tartibga soladi?":"Каким законодательным актом регулируется деятельность ННО в Узбекистане?"}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="container  answer-3 close" style={{marginBottom:'20px'}}>
                            <h2>
                                {uz?"O‘zbekistondagi nodavlat notijorat tashkilotlar faoliyati O‘zbekiston Respublikasi Vazirlar Mahkamasining 2014-yil 10-martdagi “O‘zbekiston Respublikasi Prezidentining «Fuqarolik jamiyati institutlarini rivojlantirishga ko‘maklashish borasidagi qo‘shimcha chora-tadbirlar to‘g‘risida» 2013-yil 12-dekabrdagi PQ-2085-son qarorini amalga oshirish chora-tadbirlari haqida”gi 57-sonli Qarori asosida tartibga solinadi."
                                :"Деятельность негосударственных некоммерческих организаций в Узбекистане регулируется Постановлением Кабинета Министров Республики Узбекистан № 57 от 10 марта 2014 г. «О мерах по реализации Постановления Президента Республики Узбекистан от 12 декабря 2013 года № ПП-2085 «О дополнительных мерах по оказанию содействия развитию институтов гражданского общества»"
                                }
                            </h2>
                        </div>
                    </div>
                    <div className="questions-page">
                        <div className="page-title-lenta lenta-4 before-active">
                            <div className="container">
                                <Row>
                                    <Col span={2}>
                                        <div className="discuss discuss-4 discuss-color-before">
                                            <FaDiscourse/>
                                        </div>
                                    </Col>
                                    <Col span={22}>
                                        <div className="question question-4 before-active "  onClick={()=>Show_answer('answer-4','lenta-4','discuss-4','question-4')}>
                                            {uz?"Qanday qilib mustaqil o‘qituvchilar assotsiatsiyasiga a’zo bo‘lishim mumkin?":"Как я могу вступить в независимую ассоциацию учителей?"}
                                            </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="container answer-4 close" style={{marginBottom:'20px'}}>
                            <h2>
                                {uz?"Mustaqil o‘qituvchilar assotsiatsiyasiga a’zo bo’lish uchun portalimizdagi vakillik birlashmalari ro‘yxatini ko‘ring (\"Loyiha to‘g‘risida\" - \"Bizning jamoa\") va tanlangan mustaqil assotsiatsiya saytida onlayn ariza qoldiring.":"Для вступления в независимую ассоциацию учителей, ознакомьтесь со списком представленных ассоциаций на нашем портале («О проекте» - «Наш состав») и оставьте онлайн заявку на сайте выбранной независимой ассоциации."}
                            </h2>
                        </div>
                    </div>
                    <div className="questions-page">
                        <div className="page-title-lenta lenta-5 before-active">
                            <div className="container">
                                <Row>
                                    <Col span={2}>
                                        <div className="discuss discuss-5 discuss-color-before">
                                            <FaDiscourse/>
                                        </div>
                                    </Col>
                                    <Col span={22}>
                                        <div className="question question-5 before-active" onClick={()=>Show_answer('answer-5','lenta-5','discuss-5','question-5')}>
                                            {uz?"Portal forumida qanday qilib professional maslahat olishim yoki savol berishim mumkin?":"Как я могу получить профессиональную консультацию или задать вопрос в форуме портала?"}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="container  answer-5 close" style={{marginBottom:'20px'}}>
                            <h2>
                                {uz?"Buning uchun siz portalda ro‘yxatdan o‘tishingiz kerak.":"Для этого необходимо зарегистрироваться на портале."}
                            </h2>
                        </div>
                    </div>

                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Faq
)
