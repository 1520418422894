import React from 'react';
import './../horizontalMenu.css'
import {Button, Col, Modal, Row, Form, Input} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {FaUser} from "react-icons/all";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
const { confirm } = Modal;
class ResetPassword extends React.Component{
    state={
        visible:false,
    };
    showModal=()=>{
        this.setState({
            visible:true
        })
    }
    onClose = () => {
        this.setState({
            visible:false
        })
    };

    confirmLogOut=()=> {
        console.log("nkdjsajk")
        confirm({
            className:"LogoutCofirm",
            title:"Tizimdan chiqmoqchimisiz?",
            icon: <ExclamationCircleOutlined />,
            content: "Tizimdan chiqqandan so'ng, parolingizni qayta kiritishingiz kerak bo'ladi.",
            okText: "Chiqish",
            cancelText: "Bekor qilish",
            centered: true,
            onOk() {
                // deleteCookie(userAccessTokenName);
                window.location.reload()

            },
        });
    };
    onFinish=(values)=>{

        // loginUser({
        //     ...values
        // }).then((res) => {
        //     if (res && res.status === 200 && res.data) {
        //         setCookie(userAccessTokenName, res.data.token)
        //         this.onClose()
        //         window.location.reload()
        //     } else {
        //         message.error("Something went wrong!")
        //     }
        // })
    }
    openSignUp=()=>{
        this.onClose()
        this.props.openSignUp();
    }
    render() {
        const {uz,en,current_user,sidebar}=this.props;
        return (

            <Col className="gutter-row">
                <p
                    onClick={this.showModal}
                    className={sidebar?'sidebar_voyti_btn':'voyti_btn'}>
                    <h3
                        style={{cursor:'pointer',textAlign:'center',marginLeft:'-20%'}}
                        className={'remember_password'} >{uz?"Parolni tiklash":"Восстановить пароль"}
                    </h3>
                </p>

                <Modal
                    footer={false}
                    title={<h3 style={{textAlign:'center'}}>
                        <div className="page-title-content">
                    <div className="title-content">

                    <p style={{fontSize:'24px',fontWeight:'60',textAlign:'center',marginLeft:'30px'}}>{uz?"Parolni tiklash":"Восстановить пароль"}</p>
                    </div>
                    <div className="title-content">


                        <span className="title-bottom-linear signin"></span>
                    </div>

                </div></h3>}
                       visible={this.state.visible}
                       onCancel={this.onClose}
                       onOk={this.onFinish}
                       className={'Sign_modal'}>
                    <Form
                        style={{
                            width:'100%',
                            position:'absolute',
                        }}
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            style={{
                                marginLeft:'5%',
                                width:'100%'
                            }}
                            name="username"
                            rules={[{ required: true, message:uz?"Email kiriting":en?"Enter email":" Эл. адрес" }]}
                        > <p className="have">
                            {uz?"Email kiriting":"Введите ваш E-mail"}
                        </p>
                            <Row><Col span={20}><div style={{width:'70%'}}><Input placeholder="E-mail"/></div></Col></Row>
                        </Form.Item>




                        <Form.Item>
                            <Button   htmlType="submit" hover={false} className={'Sign_in_button'} style={{width:'50%',marginLeft:'10%'}}><h3 style={{textAlign:'center',color:'white'}}>{uz?"Kod yuborish":"Отправить код"}</h3></Button>

                        </Form.Item>



                    </Form>
                </Modal>
            </Col>

        );
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ResetPassword
)
