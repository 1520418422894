import React from 'react';
import './../horizontalMenu.css'
import {Button, Col, Modal, Row, Form, Input, message, Dropdown,Menu} from "antd";
import {userAccessTokenName} from "../../../api/constants";
import {deleteCookie, getCookie, setCookie} from "../../../utils/useCookies";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import {MenuUnfoldOutlined,LogoutOutlined,DownOutlined,ExclamationCircleOutlined,UserOutlined} from '@ant-design/icons';
import {FaUser} from "react-icons/all";
import ResetPassword from "./resetPassword";
import {loginUser} from "../../../api/config/authentication";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import { useHistory } from "react-router-dom";
const { confirm } = Modal;
class ModalSignin extends React.Component{
    state={
        visible:false,
    };
    showModal=()=>{
        this.setState({
            visible:true
        })
    }
     onClose = () => {
        this.setState({
            visible:false
        })
    };

    confirmLogOut=()=> {
        let history = useHistory;

        confirm({
            className:"LogoutCofirm",
            title:"Tizimdan chiqmoqchimisiz?",
            icon: <ExclamationCircleOutlined />,
            content: "Tizimdan chiqqandan so'ng, parolingizni qayta kiritishingiz kerak bo'ladi.",
            okText: "Chiqish",
            cancelText: "Bekor qilish",
            centered: true,
            onOk() {
                deleteCookie(userAccessTokenName);
                window.location='/'
            },
        });
    };
    onFinish=(values)=>{

        loginUser({
            ...values
        }).then((res) => {
            if (res && res.status === 200 && res.data) {
                setCookie(userAccessTokenName, res.data.token)
                toast.success(this.props.uz?"Tizimga kirish muvaffaqiyatli amalga oshirildi!":"Войти успешно!")
                window.location.reload()
                this.onClose()

            } else {
                toast.error(this.props.uz?"Tizimga kirishda xatolik yuz berdi!":"An error Авторизация не удалась!")
            }
        })
    }
    openSignUp=()=>{
        this.onClose()
        this.props.openSignUp();
    }
    render() {
        const {uz,en,current_user,sidebar}=this.props;
        return (

            <Col className="gutter-row" span={4}>
                <ToastContainer/>
                <p onClick={this.showModal}  className={sidebar?'sidebar_voyti_btn':'voyti_btn'} style={{border:'none'}}>
                    {current_user?<FaUser/>:""}
                </p>
                {
                    getCookie(userAccessTokenName)? <Dropdown
                            overlay={
                                <Menu>

                                    <Menu.Item key="child1" style={{width:'100%'}}>
                                        <Link to={'/profile'}><h3>Profil</h3></Link>
                                    </Menu.Item>
                                    <Menu.Item key="child2" onClick={this.confirmLogOut} style={{width:'100%'}}>
                                        <LogoutOutlined />
                                        Chiqish
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button className={'first_ul'} type="text" style={{fontSize:'18px',color:'white',fontWeight:500,marginLeft:'-100%',marginTop:"-20px",width:'20%'}}>
                                <UserOutlined/>
                                {/*{current_user?current_user.firstname+' '+current_user.lastname:''}*/}
                                <DownOutlined />
                            </Button>
                        </Dropdown>:
                        <div style={{position:'absolute',top:'-14px',right:'-70px'}}>
                            <button
                                style={{
                                    marginTop:'0px',
                                    color:"#009E8F",
                                    fontSize:'16px',
                                    fontWeight:'bold',
                                    border:'none',
                                    padding:'4px 8px',
                                    outline:'none',
                                    cursor:'pointer',
                                    backgroundColor:'white'
                                }}
                                onClick={this.showModal}
                                className={sidebar?'sidebar_voyti_btn':'voyti_btn '}>
                                {uz?"Kirish":en?"Sign in":"Войти"}
                            </button>
                        </div>
                }
                <Modal footer={false}  title={<h3 style={{textAlign:'center'}}>
                    <div className="page-title-content">
                    <div className="title-content">

                    </div><p style={{fontSize:'24px',fontWeight:'600'}}>{uz?"Kirish":"Вход"}</p>
                    <div className="title-content">


                        <span className="title-bottom-linear signin"></span>
                    </div>

                </div></h3>}
                        visible={this.state.visible}
                        onCancel={this.onClose}
                        onOk={this.onFinish}
                        className={'Sign_modal'}>
                    <Form
                        style={{
                            width:'100%',
                            position:'absolute'
                        }}
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            style={{
                                marginLeft:'5%',
                                width:'100%'
                            }}
                            name="username"
                            rules={[{ required: true, message:uz?"Loginingizni kiriting":en?"Enter login":" Введите логин" }]}
                        >
                            <Row><Col span={20}><div style={{width:'70%'}}><Input placeholder={uz?"Loginingizni kiriting":en?"Enter login":" Введите логин" }/></div></Col></Row>
                        </Form.Item>

                        <Form.Item
                            style={{
                                marginLeft:'5%',
                                width:'100%'
                            }}
                            name="password"
                            rules={[{ required: true, message: uz?"Parolni kiriting":en?"Enter password":"Введите пароль"}]}
                        >
                            <Row><Col span={20}><div style={{width:'70%'}}><Input.Password placeholder={uz?"Parolni kiriting":en?"Enter password":"Введите пароль"}/></div></Col></Row>
                        </Form.Item>



                        <Form.Item>
                            <button   htmlType="submit" hover={false} className={'Sign_in_button'} style={{width:'30%',marginLeft:'20%',cursor:'pointer',border:'none',borderRadius:'10px',outline:'none',paddingTop:"5px"}}>
                                <h3 style={{textAlign:'center',color:'white'}}>
                                    {uz?"Kirish":en?"Come In":"Войти"}
                                </h3>
                            </button>
                        </Form.Item>
                        <Row>
                            <Col span={4}></Col>
                            <Col md={16} xs={24}>
                               <div>
                                   <ResetPassword/>
                               </div>
                            </Col>
                            <Col span={4}></Col>

                        </Row>
                        <Form.Item>
                            <p className="have" style={{marginLeft:"30px" }}>

                                {uz?"Sizda profil yo‘qmi?":"У Вас нет учетной записи?"}
                            </p>
                            <Link to='/sign-up'>
                           <Row>
                               <Col span={24}>
                               <div className="" style={{marginTop:'80px',marginLeft:'10%',marginBottom:'10px'}}>
                                                         <button onClick={this.openSignUp} className="button mission-btn grands-btn" id='signBtn' >

                                                         {uz?"Ro'yxatdan o'tish":en?"Sign Up":"Регистрация"}
                                                             </button>

                                                    </div>

                               </Col>
                           </Row>
                             </Link>

                        </Form.Item>
                    </Form>
                </Modal>
            </Col>

        );
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ModalSignin
)
