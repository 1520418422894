import React from 'react';
import './grands.css';
import '../../../../styles/global.css'
import {
    Row,
    Col,
    Card,
} from 'antd';
import {
    FaArrowDown,
} from 'react-icons/fa';
import {Link} from 'react-router-dom'
import {connect} from "react-redux";
import {uzLanguage} from "../../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../../redux/actions/EnLanguage";
import {getContests} from "../../../../api/config/Servises";
import {host, port} from "../../../../api/host";
class Grands extends React.Component{
    state={
        contests:[],
    }
    componentDidMount() {
        getContests(0,3).then(res=>{
            if(res&&res.data&&res.data.content){
                this.setState({
                    contests:res.data.content,
                })
            }
        }).catch(res=>{
            console.log('error')
        })
    }

    render() {
        const {uz,en}=this.props;
        const {contests}=this.state;
        return(
            <>
                <div className="news-page">
                    <div className=" container">
                        <div className="page-title-content">
                            <div className="title-content">
                                <p className="home-page-title">

                                    {uz?"100+ tanlov va grantlar":" 100+ Конкурсов и грантов"}
                                </p>
                            </div>
                            <div className="title-content">
                                <span className="title-bottom-linear"></span>
                            </div>
                            <div className="d-flex">
                                <p className="mainColor little-text">{
                                    uz?"Mamlakat va butun dunyo bo'ylab so'nggi tanlovlar, grantlar va ko'rgazmalarga kirish huquqiga ega bo'ling":"Получите доступ к новейшим конкурсам, грантам и выставкам в стране и по всему миру"
                                }
                                </p>
                            </div>
                        </div>

                        <div className="card-page">
                            <Row>
                                {
                                    contests?contests.map(function (item){
                                        return <Col lg={8} md={12} sm={24} xs={24}>
                                            <div className={'news-card grand-card'} data-aos={'zoom'}>
                                                <div className="img-div">
                                                    <img src={host+':'+port+'/api/auth/file/'+item.logo.id} alt="" className="w100"/>
                                                </div>
                                                <div className="d-flex " style={{padding:'5px 10px'}}>
                                                    <p className={"news-card-big-title"}>
                                                    {uz?item.titleUz:item.titleRu}
                                                    </p>
                                                    <p className={"news-card-little-text grands-card-little-text"} dangerouslySetInnerHTML={{__html: uz?item.contentUz:item.contentRu}} />


                                                </div><a href={item.link} style={{color:"white"}} target={'_blank'}>
                                               <div className="d-flex-center">
                                                         <button className="button mission-btn grands-btn" >

                                                            {uz?"Batafsil ma'lumot":"Подробнее"}
                                                             </button>

                                                    </div>
                                                 </a>

                                            </div>
                                        </Col>
                                    }):""
                                }



                            </Row>
                            <div className="d-flex-center">
                                <Link to={'/contests'}><h2 className="mainColor">
                                    <ins style={{cursor:'pointer',textDecoration:'none'}}>{uz?"Batafsil":"Показать еще"} <FaArrowDown/></ins>
                                </h2></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Grands
)
