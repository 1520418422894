import React from 'react';
import {Card, Col, Row} from "antd";
import { connect } from 'react-redux';

class ConsultationItem extends React.Component{
    render() {
        const {SelectId,uz,en}=this.props;
        return (
            <div>
                <div className="item-area">
                    <div className="title-page">
                        <p className="big-title-route">
                            {uz?"Maslahatlar":"Консултасия"}
                        </p>

                    </div>
                    <div className="table-area">
                        <table>
                            <tr>
                                <td><b>No1</b></td>
                                <td><b>{uz?"Mavzu":"Тема"}</b></td>
                                <td><b>{uz?"Sana":"Дата"}</b></td>
                                <td><b>{uz?"Holat":"Статус"}</b></td>
                            </tr>
                            <tr>
                                <td>1.</td>
                                <td className={'mainColor'} onClick={()=>SelectId(1)} style={{cursor:'pointer'}}>{uz?"Yuridik maslahat":"Правовая консултация"}</td>
                                <td>28.10.2021</td>
                                <td>{uz?"Javobni oling":"Ответь получень"}</td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{})(
    ConsultationItem
)