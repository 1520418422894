import React from 'react';
import './forum.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Row,
    Col,
    Select,
    Button,
    Modal,
    Form,
    Input,
    message,
} from 'antd';
import '../../styles/global.css';
import {
    FaDiscourse,
}from 'react-icons/fa';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getMe} from "../../api/config/authentication";
import {deleteCookie} from "../../utils/useCookies";
import {userAccessTokenName} from "../../api/constants";
import {getForumById, getForums, getForumsByReply, postForum} from "../../api/config/Servises";
import { month } from '../../constants/month';
import {host, port} from "../../api/host";
const {Option}=Select;
class ForumByQuestion extends React.Component {
    state={
        current_user:null,
        forum:{},
        totalElements:0,
        forums:[],
        dateTime:null,
        data:null,
        size:3,
        avatarId:null,
    }
    componentDidMount() {

        getMe().then(res => {
            if (res && res.data) {
                this.setState({
                    current_user: res.data,
                    avatarId:res.data.avatarId?res.data.avatarId:null,
                })
            } else {
                deleteCookie(userAccessTokenName)
            }
        });
        getForumById(window.location.pathname.split('/')[2]).then(res=>{
            if (res&&res.data) {
                console.log(res.data);
                this.setState({
                    forum:res.data,
                    data:new Date(res.data.createAt).getDate()+"."+new Date(res.data.createAt).getMonth()+"."+new Date(res.data.createAt).getFullYear(),
                    dateTime:new Date(res.data.createAt).getHours()+":"+new Date(res.data.createAt).getMinutes()
                })
                
            }
        })
        getForumsByReply(window.location.pathname.split('/')[2],0,3).then(res=>{
            if (res&&res.data) {
              
              this.setState({
                totalElements:res.data.totalElements,
                forums:res.data.content,
              })
            }
        }).catch(error=>{
            console.log(error);
        })

    }

    render() {
        const {uz,en}=this.props;
        const {current_user,forum,forums,totalElements,dateTime,data,avatarId}=this.state;
        const PostForum=()=>{
            if(!current_user){
                toast.warn(uz?"Javob yuborish uchun ro'yxatdan o'tishingiz yoki tizimga kirishingiz kerak":"Вы должны зарегистрироваться или войти в систему, чтобы оставить ответ");
            }
            var forum={
                content:document.querySelector('.consultation-input').value,
                replyId:window.location.pathname.split('/')[2]
            }
            postForum(forum).then(res=>{
                if (res&&res.data) {
                    getForumsByReply(window.location.pathname.split('/')[2],0,this.state.size).then(res=>{
                        if (res&&res.data) {
                          
                          this.setState({
                            totalElements:res.data.totalElements,
                            forums:res.data.content,
                          })
                        }
                    })
                    document.querySelector('.consultation-input').value='' 
                }
            }).catch(error=>{
                console.log(error);
            })
        }
        const getMoreForums=()=>{
            getForumsByReply(window.location.pathname.split('/')[2],0,this.state.size+3).then(res=>{
                if (res&&res.data) {
                    this.setState({
                        totalElements:res.data.totalElements,
                        forums:res.data.content,
                        size:this.state.size+6,
                      })
                }
            })
        }
        return (
            <div style={{width:'100%',overflowX:'hidden'}}>
                <ToastContainer />
                <HorizontalMenu/>
                <div className="faq-page">
                    <div className="container">
                        <div className="title-page">
                            <p className="title-route" style={{width:'100%'}}><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa ":"Главная"}</Link>   > <span className="mainColor">Форум</span></p> <br/>
                        </div>
                        <h1 style={{fontWeight:'bold'}}>
                            {forum.content}
                        </h1>
                        <p style={{color:'grey'}}>
                            {totalElements} {uz?"javoblar":"ответов"}
                        </p>
                    </div>
                    <div className="questions-page">
                        <div className="page-title-lenta">
                            <div className="container">
                                <Row>

                                    <Col span={22}>
                                        <div className="" style={{fontSize:'22px'}} className={'mainColor'}>
                                        {forum.content}    
                                        </div>

                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <div className="container " style={{marginBottom:'20px'}}>
                            <div className="p10">
                                <Row>
                                    <Col span={3}  className="p10">
                                        <div className="user-avatar" style={{marginTop:'15px'}}>
                                            <img src={forum.userAvatar?host+":"+port+'/api/auth/file/'+forum.userAvatar.id:"/images/avatar.jpg"} alt="User"/>
                                        </div>
                                        <div className="d-flex-center">
                                            <p className="fullname" style={{color:'rgba(0,0,0,0.7)'}}>
                                                {forum.userFullname}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={21}  className="p10">
                                        <div className="question ">
                                            <p style={{color:'rgba(0,0,0,0.7)'}}>
                                               {forum.content}
                                            </p>
                                            <Row>
                                                <Col span={2}>
                                                    <p style={{color:'grey',fontSize:'14px'}}>
                                            
                                                        {dateTime}
                                                        {/* {date.getDay()}-{uz?month[date.getMonth()].titleUz:month[date.getMonth()].titleRu} {date.getFullYear()}-{uz?"yil":"год"} */}
                                                    </p>
                                                </Col>
                                                <Col span={4}>
                                                    <p style={{color:'grey',fontSize:'14px'}}>
                                                        {data}
                                                    </p>
                                                </Col>
                                                <Col span={12}>

                                                </Col>
                                                <Col span={6}>
                                                   <label for={'reply'}>
                                                   <p className={'mainColor'} style={{cursor:'pointer'}}>
                                                        Ответить
                                                    </p>
                                                   </label>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>
                            </div>

                            {
                                forums&&forums.map(function(item){

                                    return <div className="p10">
                                    <Row>
                                        <Col span={2}></Col>
                                        <Col span={3}  className="p10">
                                            <div className="user-avatar" style={{marginTop:'15px'}}>
                                                <img src={item.userAvatar?host+':'+port+'/api/auth/file/'+item.userAvatar.id:'/images/avatar.jpg'} alt="User"/>
    
                                            </div>
                                            <div className="">
                                                <p className="fullname" style={{color:'rgba(0,0,0,0.7)'}}>
                                                    {item.userFullname}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col span={19}  className="p10">
                                            <div className="question ">
                                                <p style={{color:'rgba(0,0,0,0.7)'}}>
                                                    {item.content}
                                                </p>
                                                <Row>
                                                    <Col span={2}>
                                                        <p style={{color:'grey',fontSize:'14px'}}>
                                                            17:14
                                                        </p>
                                                    </Col>
                                                    <Col span={4}>
                                                        <p style={{color:'grey',fontSize:'14px'}}>
                                                            10.11.2021
                                                        </p>
                                                    </Col>
                                                    <Col span={12}>
    
                                                    </Col>
                                                   
                                                </Row>
                                            </div>
                                        </Col>
    
                                    </Row>
                                </div>
                                })
                            }
                        </div>

                    </div>

                    <div className="d-flex-center">
                      {totalElements>0? totalElements>3? <button className="button mission-btn news-btn"  onClick={getMoreForums}>
                            {uz?"Ko'proq ko'rsatish":"Показать еще"}
                        </button>:"":<p className={' fs-fw d-flex-center p10'}>
                                
                                 </p>}
                    </div>
                    <div className="container">
                    <div className="p10">
                            <Row>
                                <Col span={4}  className="p10">
                                    <div className="user-avatar" style={{marginTop:'15px'}}>
                                        <img src={avatarId?host+':'+port+'/api/auth/file/'+avatarId:'/images/avatar.jpg'} alt="User"/>

                                    </div>
                                    <div className="d-flex-center">
                                        <p className="fullname" style={{color:'rgba(0,0,0,0.7)'}}>
                                        <span className="mainColor">
                                            Siz:
                                        </span>
                                        {this.state.current_user&&this.state.current_user.firstname+" \n"+this.state.current_user.lastname}
                                            
                                        </p>
                                    </div>
                                </Col>
                                <Col span={20}  className="p10">
                                    <div style={{minHeight:'150px'}} className="title-area-forum consultation-input-area p10 consultation-textarea">
                                        <textarea name={'reply'} id={'reply'} placeholder={uz?"Matn":"Tекст"} className="consultation-input consultation-textarea"/>
                                    </div>
                                    <button className="button mission-btn news-btn" onClick={PostForum}>
                                        {uz?"Javob yuborish":"Ответить"}
                                    </button>
                                </Col>

                            </Row>
                        </div>
         
                    </div>

                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ForumByQuestion
)
