import React from 'react';
import {
    Row,
    Col,
} from 'antd';
import '../../styles/global.css';
import { FaArrowRight,
} from 'react-icons/fa';
import {
    Link
} from 'react-router-dom';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getContests, getNews} from "../../api/config/Servises";
import {host, port} from "../../api/host";
import {month} from "../../constants/month";
import Loader from "../../commonComponents/loader/loader";

class All_News extends React.Component {
    state={
        news:[],
        size:3,
        loading:true,
    }

    componentDidMount() {
        getNews(0,this.state.size).then(res=>{
            if (res && res.data&&res.data.content){
                this.setState({
                    news:res.data.content,
                    loading:false,
                })


            }
        }).catch(res=>{
            console.log('error')
        })


    }

    render() {
        const {
            news,
            size,
        }=this.state;
        const {uz,en}=this.props;
        const More_news=()=>{
            this.setState({
                size:size+3,
            })
            getNews(0,size+3).then(res=>{
                if (res&&res.data&&res.data.content){
                    this.setState({
                        news: res.data.content
                    })
                }
            }).catch(res=>{
                console.log('error')
            })
        }
        return (
            <><HorizontalMenu/>
                {
                    this.state.loading?<Loader style={{marginBottom:'20px'}}/>:<div>

                        <div className="faq-page">

                            <div className="container">
                                <div className="title-page">
                                    <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>    > <span className="mainColor">{uz?"Yangiliklar":"Новости"}</span></p> <br/>

                                </div>
                                <div className="title-page">
                                    <p className="big-title-route">
                                        {uz?"Yangiliklar":"Новости"}
                                    </p>

                                </div>
                                <div className="all-contests">
                                    {
                                        news?news.map(function (item) {
                                            var date=new Date(item.createAt)
                                            return <div className="contest"  data-aos={'zoom-in'}>
                                                <Row>
                                                    <Col md={8} sm={24}>
                                                        <div className="w100 contest-img p10">
                                                            <img src={host+':'+port+'/api/auth/file/'+item.logo.id}  alt=""/>
                                                        </div>
                                                    </Col>
                                                    <Col md={16} sm={24}>
                                                        <div className="w100 p10">
                                                            <Link to={'/news/'+item.id}>
                                                                <h2 className=" contest-title">
                                                                    {uz?item.titleUz:item.titleRu}
                                                                </h2>
                                                            </Link>
                                                            <Row>
                                                                <Col md={12} sm={24}>
                                                                    <p className="mainColor" style={{fontWeight:700}}>
                                                                        {date.getDate()}-{uz?month[date.getMonth()].titleUz:month[date.getMonth()].titleRu} {date.getFullYear()}-{uz?"yil":"год"}
                                                                    </p>
                                                                </Col>

                                                                <Col span={24}>
                                                                    <p className="fs-fw news_card-desc" dangerouslySetInnerHTML={{__html: uz?item.contentUz:item.contentRu}}/>


                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="right-arrow-icon">
                                                    <Link to={'/news/'+item.id}>
                                                        <FaArrowRight/>
                                                    </Link>

                                                </div>
                                            </div>
                                        }):""
                                    }

                                    <div className="d-flex-center">
                                        <button className="button mission-btn news-btn" onClick={More_news}>
                                            {uz?"Ko'proq ko'rsatish":"Показать еще"}
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                }
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    All_News
)
