import React from 'react';
import './mainSlider.css';
import '../../../../styles/global.css';
import AOS from 'aos';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Row,
    Col, Dropdown, Menu,
    Select
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import {FaBars, FaUser} from "react-icons/all";
import {MenuList} from "../../../../constants/menuList";
import {connect} from "react-redux";
import {enLanguage} from "../../../../redux/actions/EnLanguage";
import {uzLanguage} from "../../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../../redux/actions/RuLanguage";
import ModalSignin from "../../../../commonComponents/horizontalMenu/components/ModalSignin";
import ModalSignup from "../../../../commonComponents/horizontalMenu/components/ModalSignup";
import {getMainSliders} from "../../../../api/config/Servises";
import {host, port} from "../../../../api/host";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
AOS.init({
    duration:1500,
});
class CarouselComponent extends React.Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    state={
        lang:'Uz',
        signupModal:false,
        mainSliders:[],
    };
    componentDidMount() {

        const {uz,en}=this.props;
        this.setState({
            lang:uz?'Uz':en?'En':'Ru',

        })
        getMainSliders().then((res)=>{
            if (res){
                this.setState({
                    mainSliders:res.data,
                });
            }else{
                console.log('slider error')
            }
        });
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    openSignUp=()=>{
        this.setState({
            signupModal:true
        })
    }
    render() {
        const {lang,
            mainSliders,
        }=this.state;
        const {uz,en}=this.props;
        const changeSelect=(value)=>{
            console.log(value)
            if (value==="UZ"){
                this.props.uzLanguage();
            }else if (value==="EN") {
                this.props.enLanguage();
            }else {
                this.props.ruLanguage();
            }
        };
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:true,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            customPaging: bb => (
                <div
                    onMouseDown={this.bb}
                    className="carousel-dots "
                    style={{
                        marginTop:'-10px',
                    }}
                >
                </div>
            )
            // responsive: [
            //     {
            //         breakpoint: 1024,
            //         settings: {
            //             slidesToShow: 3,
            //             slidesToScroll: 3,
            //             infinite: true,
            //             dots: true
            //         }
            //     },
            //     {
            //         breakpoint: 600,
            //         settings: {
            //             slidesToShow: 2,
            //             slidesToScroll: 2,
            //             initialSlide: 2
            //         }
            //     },
            //     {
            //         breakpoint: 480,
            //         settings: {
            //             slidesToShow: 1,
            //             slidesToScroll: 1
            //         }
            //     }
            // ]
        };
        const about_proect = (
            <Menu className={'menu-dropdown'}>
                <Menu.Item>

                        <Link to={'/about/our-mission'} ><p style={{fontWeight:'700',fontSize:'16px'}}> {uz?"Maqsadimiz":en?"":"НАША МИССИЯ"}</p></Link>


                </Menu.Item>
                <Menu.Item  >

                        <Link to={'/about/gallery'}><p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Galereya":en?"":"ГАЛЕРЕЯ"}</p></Link>

                </Menu.Item>
                <Menu.Item>

                        <Link to={'/about/compositions'}><p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Assotsiyatsiyalar":en?"":"Ассоциации"}</p></Link>



                </Menu.Item>
                <Menu.Item  >

                        <Link to={'/about/contact'}> <p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Biz bilan bog’lanish":en?"":"КОНТАКТЫ"}</p></Link>

                </Menu.Item>
            </Menu>
        );
        const forum_list = (
            <Menu className={'menu-dropdown'}>
                <Menu.Item>

                    <Link to={'/forum'} ><p style={{fontWeight:'700',fontSize:'16px'}}>{uz?"FORUM":en?"":"ФОРУМ"}</p></Link>


                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/faq'}><p  style={{fontWeight:'700',fontSize:'16px'}}>FAQ</p></Link>

                </Menu.Item>

            </Menu>
        );

        return(
            <>
                <div style={{width:'100%',overflow:'hidden',position:'relative'}}>
                    <div className="home-menu-slider">
                        <Row>
                            <Col span={6}>
                                <Link to={'/home'}>
                                    <Row>
                                        <Col span={12}>
                                            <div className={'logo-area'}>
                                                <img src="/images/EU.png" alt=""/>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className={'logo-area itsm-logo'}>
                                                <img src="/images/itsm.png" alt=""/>
                                            </div>
                                        </Col>
                                    </Row>

                                </Link>

                            </Col>
                            <div  className={'bars-icon'} >
                                <Link to={'/sidebar-menu'} style={{color:'white'}}>
                                    <FaBars style={{cursor:'pointer'}}/>
                                </Link>
                            </div>
                            <Col span={18}>
                                <div className="menu-list">
                                    <Row>
                                        <Dropdown trigger='click' overlay={about_proect}>
                                            <Col span={3}><p className="w100 menu-items"> <Row>
                                          <Col span={18}>{uz?"LOYIHA HAQIDA":"O ПРОЕКТE"} </Col><Col span={4}><DownOutlined /></Col>
                                          </Row>
                                            </p></Col>
                                        </Dropdown>
                                        <Dropdown trigger='click' overlay={forum_list}>
                                            <Col span={3}><p className="w100 menu-items">{uz?"FORUM":en?"":"ФОРУМ"} <DownOutlined /></p></Col>
                                        </Dropdown>
                                        {

                                            MenuList.map(item=>{
                                                return <Col span={3}><Link to={item.link}><p className="w100 menu-items">{uz?item.titleUz:en?item.titleEn:item.titleRu}</p></Link></Col>
                                            })
                                        }
                                        <Col span={3}><Link to={'/join'}><p className="w100 menu-items menu-items" >{uz?"Assotsiatsiya tuzish":en?"":"Cоздать ассоциацию"}</p></Link></Col>

                                        <Col span={3}><p className="w100 menu-items user-and-language">
                                            <Row> <Col span={12}><p className="w100" style={{marginRight:'50px'}}>
                                                <Select defaultValue={uz?"UZ":"RU"} style={{color:'#009E8F'}} onChange={changeSelect}>
                                                    <Select.Option style={{color:'#009E8F'}} value={'UZ'}>UZ</Select.Option>
                                                    <Select.Option style={{color:'#009E8F'}} value={'RU'}>RU</Select.Option>
                                                </Select>
                                            </p></Col>

                                                {/*<Dropdown trigger='click' overlay={language_dropdown}>*/}
                                                {/*    </Dropdown>*/}
                                                <Col span={12}><p className="w100"><ModalSignin openSignUp={this.openSignUp}/></p></Col>
                                            </Row>
                                        </p></Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {
                        this.state.signupModal?
                            <ModalSignup/>:''
                    }
                    <Slider {...settings}>
                        {
                          mainSliders&&mainSliders.map(item=>{
                              return <div className="main-page">
                                  <div className="big-post p5">
                                      <div className="carousel-page">

                                          <img src={host+':'+port+'/api/auth/file/'+item.imgId} alt=""
                                               className="main-slider-img"/>
                                          <div className="black-area">

                                          </div>
                                          <div className="carousel-content container">
                                              <Slider {...settings}>
                                                  <div className={'slider-item'}>
                                                      <Row>
                                                          <Col md={12} sm={24} xs={24}>
                                                             <Zoom>
                                                                 <p className={'carousel-title'} data-aos={'zoom-in'}>
                                                                     {uz?item.titleUz.split('+=')[0]:en?"":item.titleRu.split('+=')[0]}</p>

                                                              <p className="carousel-little-text">
                                                                  {uz?item.contentUz:en?"":item.contentRu}
                                                              </p></Zoom>
                                                              <Link to={item.titleUz.split('+=')[1]}>
                                                                  <Fade right>
                                                                      <button className="carousel-button">
                                                                          {uz?"Bizga qo‘shiling":en?"":"Присоединиться"}

                                                                      </button>
                                                                  </Fade>
                                                              </Link>
                                                          </Col>
                                                      </Row>
                                                  </div>


                                              </Slider>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          })
                        }



                    </Slider>

                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    CarouselComponent
)