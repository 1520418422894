import React from 'react';
import {Card, Col, Row} from "antd";
import {ImAttachment} from 'react-icons/im'
import { connect } from 'react-redux';
class ForumById extends React.Component{
    render() {
        const {SelectId,uz,en}=this.props;
        return (
            <div>
                <div className="item-area">
                    <div className="title-page">
                        <p className="big-title-route">
                            {uz?"Forum":"Форум"}
                        </p>

                    </div>
                    <div className="w100 p10">
                        <Row>
                            <Col md={3} sm={5}>
                            <h3 style={{color:"grey"}}>{uz?"Mavzu":"Тема"}</h3>
                            </Col>
                            <Col span={18}>
                                <div className="title-area-forum">
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="w100">
                        <Row>
                            <Col span={3}>
                                <h3 className={'p10'} style={{color:"grey"}}><ImAttachment/></h3>
                            </Col>
                            <Col md={2} sm={4} xs={5}>
                                <div className="p10">
                                    <div className="title-area-forum">

                                    </div>
                                </div>
                            </Col>
                            <Col  md={2} sm={4} xs={5}>
                                <div className="p10">
                                    <div className="title-area-forum">

                                    </div>
                                </div>
                            </Col>
                            <Col  md={2} sm={4} xs={5}>
                                <div className="p10">
                                    <div className="title-area-forum">

                                    </div>
                                </div>
                            </Col>
                            <Col  md={2} sm={4} xs={5}>
                                <div className="p10">
                                    <div className="title-area-forum">

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="w100 p10">
                        <Row>
                            <Col span={3}>
                                <h3 style={{color:"grey"}}>{uz?"Savollar":"Вопросы"}:</h3>
                            </Col>
                            <Col span={21}></Col>
                            <Col span={21}>
                                <div className="title-area-forum question-area">

                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="w100 p10">
                        <Row>
                            <Col span={8}>
                            <h3 style={{color:"grey"}}>{uz?"Savollarga javob":"Ответь на вопросы"}(<span className="mainColor">Т.Ибрагимов</span>):</h3>
                            </Col>
                            <Col span={21}></Col>
                            <Col span={21}>
                                <div className="title-area-forum question-area">

                                </div>
                            </Col>
                        </Row>
                    </div>


                    <button className='button news-btn mission-btn' onClick={()=>SelectId('')}>
                        {uz?"Orqaga":"Назад"}
                        </button>


                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{})(
    ForumById
)