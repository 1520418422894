import React from 'react';
import './news.css';
import {
    Row,
    Col,
} from 'antd';
import '../../styles/global.css';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getNewsById} from "../../api/config/Servises";
import {host, port} from "../../api/host";
import Loader from "../../commonComponents/loader/loader";
class News_by_id extends React.Component {
    state={
        news:{},
        img_id:'',
        loading:true,
    }
    componentDidMount() {
        getNewsById(window.location.pathname.split('/')[2]).then(res=>{
            if (res){
                this.setState({
                    news:res.data,
                    img_id:res.data.logo.id,
                    loading:false,
                })

            }
        }).catch(res=>{
            console.log('error')
        })

    }

    render() {
        const {uz,en}=this.props;
        const {
            news
        }=this.state;
        return (
            <>
                <HorizontalMenu/>
                {
                    this.state.loading?<Loader/>: <div className="faq-page">
                        <div className="container">
                            <div className="title-page">
                                <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>    > <span className="mainColor">{uz?"Yangiliklar":"Новости"}</span></p> <br/>

                            </div>
                            <p className={'title-contest'}>{news?uz?news.titleUz:news.titleRu:""}
                            </p>
                            <Row>
                                <Col md={8} sm={16}>
                                    <p className="w100 fs-fw" style={{color:'grey'}}>

                                        {
                                            news? new Date(news.createAt).getDate()+'.'+new Date(news.createAt).getMonth()+'.'+new Date(news.createAt).getFullYear():""
                                        }
                                    </p>
                                </Col>

                            </Row>

                            <div className="w100 news-title" style={{fontSize:'20px'}}>
                                <div>
                                     {
                                            news? <img src={host+':'+port+'/api/auth/file/'+this.state.img_id} alt="" style={{textAlign:'left',marginRight:'10px',width:'50%'}} align={'left'} />:""
                                        } {
                                            news? <p style={{marginLeft:'5%'}} dangerouslySetInnerHTML={{__html: uz?news.contentUz:news.contentRu}}/>:""
                                        }
                                </div>
                              

                            </div>
                        </div>


                    </div>
                }
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    News_by_id
)
