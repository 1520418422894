import  React from 'react';
import './horizontalMenu.css';
import '../../styles/global.css';
import {
    Row,
    Col,
} from 'antd';
import { Menu, Dropdown,Select } from 'antd';
import {connect} from "react-redux";
import { DownOutlined } from '@ant-design/icons';
import {MenuList} from "../../constants/menuList";
import {
    FaUser,
    FaBars,
} from "react-icons/all";
import {
    Link, Redirect,
} from "react-router-dom";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import ModalSignin from "./components/ModalSignin";
import ModalSignup from "./components/ModalSignup";

class HorizontalMenu extends React.Component{
    state={
      lang:'Uz',
      signupModal:false,
    };
    componentDidMount() {
        const {uz,en}=this.props;
        this.setState({
            lang:uz?'Uz':en?'En':'Ru',

        })
        window.scrollTo(0,0)
    }
    openSignUp=()=>{
        this.setState({
            signupModal:true
        })
    }


    render() {
        const {lang}=this.state;
        const {uz,en}=this.props;
        const changeSelect=(value)=>{
            if (value==="UZ"){
                this.props.uzLanguage();
            }else if (value==="EN") {
                this.props.enLanguage();
            }else {
                this.props.ruLanguage();
            }

        };

        const about_proect = (
            <Menu className={'menu-dropdown'}>
                <Menu.Item>

                    <Link to={'/about/our-mission'} ><p style={{fontWeight:'700',fontSize:'16px'}}> {uz?"Maqsadimiz":en?"":"НАША МИССИЯ"}</p></Link>


                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/about/gallery'}><p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Galereya":en?"":"ГАЛЕРЕЯ"}</p></Link>

                </Menu.Item>
                <Menu.Item>

                    <Link to={'/about/compositions'}><p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Assotsiyatsiyalar":en?"":"Ассоциации"}</p></Link>



                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/about/contact'}> <p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Biz bilan bog’lanish":en?"":"КОНТАКТЫ"}</p></Link>

                </Menu.Item>
            </Menu>
        );
        const forum_list = (
            <Menu className={'menu-dropdown'}>
                <Menu.Item>

                    <Link to={'/forum'} ><p style={{fontWeight:'700',fontSize:'16px'}}>{uz?"FORUM":en?"":"ФОРУМ"}</p></Link>


                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/faq'}><p  style={{fontWeight:'700',fontSize:'16px'}}>FAQ</p></Link>

                </Menu.Item>

            </Menu>
        );

        return(
            <>
                <div className="menu-page">

                      <Row>
                        <Col md={6} sm={8} xs={10}>
                            <Link to={'/'}>
                                <Row>
                                    <Col span={12}>
                                        <div className={'logo-area'}>
                                            <img src="/images/EU.png" alt=""/>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className={'logo-area itsm-logo'}>
                                            <img src="/images/itsm.png" alt=""/>
                                        </div>
                                    </Col>

                                </Row>

                            </Link>

                        </Col>
                        <div  className={'bars-icon'}>
                           <Link to={'/sidebar-menu'} style={{color:'white'}}>
                               <FaBars style={{cursor:'pointer'}}/>
                           </Link>
                        </div>
                        <Col span={18}>
                          <div className="menu-list">
                              <Row>
                                  <Dropdown trigger='click' overlay={about_proect}>
                                      <Col span={3}><p className="w100 menu-items">
                                          {uz?"LOYIHA HAQIDA":"O ПРОЕКТE"}<DownOutlined />
                                      {/*    <Row>*/}
                                      {/*    <Col span={24}>{uz?"LOYIHA HAQIDA":"O ПРОЕКТE"}<DownOutlined /> </Col>*/}
                                      {/*    <Col span={4}><DownOutlined /></Col>*/}
                                      {/*</Row>*/}
                                      </p></Col>
                                  </Dropdown>
                                  <Dropdown trigger='click' overlay={forum_list}>
                                      <Col span={3}><p className="w100 menu-items">{uz?"FORUM":"ФОРУМ"} <DownOutlined /></p></Col>
                                  </Dropdown>


                                  {
                                      MenuList.map(item=>{
                                          return <Col span={3}><Link to={item.link}><p className="w100 menu-items">{uz?item.titleUz:en?item.titleEn:item.titleRu}</p></Link></Col>
                                      })
                                  }
                                  <Col span={3}><Link to={'/join'}><p className="w100 menu-items menu-items" >{uz?"Assotsiatsiya tuzish":en?"":"Cоздать ассоциацию"}</p></Link></Col>
                                  <Col span={3}><p className="w100 menu-items user-and-language">
                                      <Row>
                                          <Col span={12}><p className="w100" style={{marginRight:'60px'}}>
                                              <Select defaultValue={uz?"UZ":"RU"} style={{color:'#009E8F'}} onChange={changeSelect}>
                                                  <Select.Option style={{color:'#009E8F'}} value={'UZ'}>UZ</Select.Option>
                                                  <Select.Option style={{color:'#009E8F'}} value={'RU'}>RU</Select.Option>
                                              </Select>
                                          </p></Col>

                                          <Col span={12}><p className="w100"><ModalSignin openSignUp={this.openSignUp}/></p></Col>
                                      </Row>
                                  </p></Col>
                              </Row>
                          </div>
                        </Col>
                    </Row>
                </div>
                {
                    this.state.signupModal?
                        <ModalSignup/>:''
                }
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    HorizontalMenu
)
