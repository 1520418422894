import React from 'react';
import {
    Card,
    Col,
    Row,
    Form,
    Input,
    message,
    Button, Select,
} from "antd";
import '../join.css';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {Redirect} from 'react-router-dom'
import {getDistrictByRegionId, getDistricts, getRegions, messageSave} from "../../../api/config/Servises";

class FormsPage extends React.Component{
    state={
        regions:[],
        districts:[],
        check:true,
    }
    componentDidMount() {
        getRegions().then(res=>{
            if(res&&res.data){
                this.setState({
                    regions:res.data,
                })

            }
        })
        getDistricts().then(res=>{
            if(res&&res.data){
                this.setState({
                    districts:res.data,
                })
            }
        })
    }

    render() {
        const {uz,en}=this.props;
        const {regions,districts,check}=this.state;
        const selectRegion=(e)=>{

            getDistrictByRegionId(e).then(res=>{
                if (res&&res.data){
                    this.setState({
                        districts: res.data,
                    })
                }
            })
        }
        const key = 'updatable';
        const onFinish=(e)=>{ 
           message.loading({ content: 'Saqlanmoqda...', key });
           messageSave(e).then(res=>{
               if (res){
               
                setTimeout(() => {
                  message.success({ content: 'Saqlandi!', key, duration: 2 });
                  
                  window.location.pathname='/home'
                }, 1000);
               }
           }).catch(res=>{
               console.log('error')
           })
        }
        const checked=()=>{
            this.setState({
                check:false,
            })
        }
        return (
            <div className={'p10'}>
                <div className="page-title-content ">
                    <div className="title-content">
                        <p className="home-page-title">

                            {uz?"Nimadan boshlashni bilmayapsizmi?":"НЕ ЗНАЕТЕ, С ЧЕГО НАЧАТЬ?"}
                        </p>
                    </div>
                    <div className="title-content">
                        <span className="title-bottom-linear"></span>
                    </div>
                    <p className="mainColor little-text">{uz?"Ma’lumotlaringizni yuboring va bizning mutaxassislarimiz Siz bilan bog‘lanadilar":"Отправьте нам свои данные и наши специалисты свяжутся с вами"}</p>

                </div>
                <div className="container ">

                        <Form
                            // form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        ><Row>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="fullname"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"F.I.Sh. (to‘liq)":"Ф.И.О. (полностью)"} :</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Manzilingiz:":"Ваш адрес:"}</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="phoneNumber"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Telefon raqamingiz":"Ваш номер телефона"}:</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="address"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Maktabingiz qaysi hududda joylashgan":"В какой области находится Ваша школа"}?</span>}
                                        className={'w100 '}

                                    >
                                        <Select style={{border:'1px solid black',borderRadius:0,minHeight:'30px',fontWeight:'bold'}} onChange={selectRegion} >
                                            {
                                                regions?regions.map(item=>{
                                                    return <Select.Option   value={item.id}>
                                                        {uz?item.nameUz:item.nameRu}
                                                    </Select.Option>
                                                }):""
                                            }
                                 
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="schooldistrictId"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Maktabingiz qaysi tumanda joylashgan":"В каком районе расположена Ваша школа"}?</span>}
                                        className={'w100 '}

                                    >
                                        <Select style={{border:'1px solid black',borderRadius:0,minHeight:'30px',fontWeight:'bold'}} >
                                            {
                                                districts?districts.map(item=>{
                                                    return <Select.Option  value={item.id}>
                                                        {uz?item.nameUz:item.nameRu}
                                                    </Select.Option>
                                                }):""
                                            }

                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="school"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Maktabingiz raqami ":"Номер Вашей школы"} №:</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="association"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Qaysi yo‘nalishda assotsiatsiya tashkil etmoqchisiz":"В каком направлении вы хотите создать ассоциацию"}?</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="purpose"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Assotsiatsiya tashkil etishdan maqsadingiz nima":"Какова Ваша цель в создании независимой ассоциации"}?</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input.TextArea style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col md={8} sm={24} xs={24} className={'p10'}>
                                <div style={{ overflow: 'hidden' }}>
                                    <Form.Item
                                        name="email"
                                        label={<span className={'forum-item-span'} style={{fontWeight:'bold'}}>{uz?"Elektron pochtangiz":"Ваш адрес электронной почты"}:</span>}
                                        className={'w100 p10'}

                                    >
                                        <Input style={{border:'1px solid black',borderRadius:0,minHeight:'30px'}} />
                                    </Form.Item>
                                </div>
                            </Col>

                        </Row>
                            <Row>
                                <Col span={8}>

                                </Col>
                                <Col span={12}>
                                    <div style={{ overflow: 'hidden' }}>
                                        <Form.Item
                                            name="email"
                                            className={'w100 p10'}

                                        >
                                            <Input type={'checkbox'} onChange={checked}/>  <Link><ins>{uz?"Shaxsiy ma’lumotlarimni qayta ishlashga rozilik bildiraman":"Даю согласие на обработку моих персональных данных"}</ins></Link>
                                        </Form.Item>
                                    </div>
                                </Col>

                            </Row>
                         

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <button disabled={check}  className="button news-btn mission-btn" htmlType="submit">
                                    {uz?"Yuborish":"Отправка данных"}
                                </button>
                            </Form.Item>

                        </Form>



                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    FormsPage
)