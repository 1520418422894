import React from 'react'
import './contact.css';
import {
    FaPhoneAlt,
    FaInstagram,
    FaTelegramPlane,
    FaFacebookF,
    FaYoutube,
    FaGooglePlusG,
} from 'react-icons/fa';
import {
    BiMessageDetail,
    BiTimeFive
} from 'react-icons/bi'
import {
    HiOutlineLocationMarker
} from 'react-icons/hi'
import {
    Row,
    Col
} from 'antd';
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";

class Contact extends React.Component{
    render() {
        const {uz,en}=this.props;
        return(
            <>
                <Row>
                    <Col lg={8} md={24} sm={24}>
                        <Row>
                            <Col span={4}>
                                <div className="contact-icon">
                                    <FaPhoneAlt/>
                                </div>
                            </Col>
                            <Col span={20}>
                                <Row>
                                    <Col md={24} sm={12}>
                                        <p className="tel">
                                            {uz?"Telefon":"телефон"}
                                        </p>

                                    </Col>
                                    <Col md={24} sm={12}>
                                        <p style={{fontSize:'22px',marginTop:''}}>
                                            +998 71 202 09 09
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                        <Col span={4}>
                            <div className="contact-icon">
                                <BiMessageDetail/>
                            </div>
                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col md={24} sm={12}>
                                    <p className="tel">
                                         {uz?"email":"Эл. адрес"}
                                    </p>

                                </Col>
                                <Col md={24} sm={12}>
                                    <p style={{fontSize:'22px',marginTop:''}}>
                                        info@itsm.uz
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                        <Row>
                            <Col span={4}>
                                <div className="contact-icon">
                                    <BiTimeFive/>
                                </div>
                            </Col>
                            <Col span={20}>
                                <Row>
                                    <Col md={24} sm={12}>
                                        <p className="tel">
                                            {uz?"Ish tartibi":"режим работы"}
                                        </p>

                                    </Col>
                                    <Col md={24} sm={12}>
                                        <p style={{fontSize:'22px',marginTop:''}}>
                                             {uz?"Dushanba-Juma 9:00 dan 18:00 gacha":"Пн-Пт с 9:00 до 18:00"}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <div className="contact-icon">
                                    <HiOutlineLocationMarker/>
                                </div>
                            </Col>
                            <Col span={20}>
                                <Row>
                                    <Col md={24} sm={12}>
                                        <p className="tel">
                                            {uz?"Manzil":"адрес"}
                                        </p>

                                    </Col>
                                    <Col md={24} sm={12}>
                                        <p style={{fontSize:'22px',marginTop:''}}>
                                            {uz?"100187, Toshkent shahri, Shayxontohur tumani, Navoiy ko‘chasi, 2a.":"100187, город Ташкент, Шайхантахурский район, улица Навои, 2а."}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                        <Col span={4}>

                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col md={24} sm={12}>
                                    <p className="tel">
                                        {uz?"Biz ijtimoiy tarmoqlarda":" мы в соцсетях"}
                                    </p>

                                </Col>
                                <Col md={24} sm={12}>
                                   <Row>
                                       <Col span={6}>
                                           <p className="tel">
                                               <a href="https://www.facebook.com/itsc.uz/" target={'_blank'} style={{color:'#009E8F'}}><FaFacebookF/></a>
                                           </p>
                                       </Col>
                                       <Col span={6}>
                                           <p className="tel">
                                               <a href="http://instagram.com/itsm_uz" target={'_blank'} style={{color:'#009E8F'}}><FaInstagram/></a>
                                           </p>

                                       </Col>
                                       <Col span={6}>
                                           <p className="tel">
                                               <a href="https://t.me/itsmuzb" target={'_blank'} style={{color:'#009E8F'}}><FaTelegramPlane/></a>
                                           </p>

                                       </Col>
                                       <Col span={6}>
                                           <p className="tel">
                                               <a  style={{color:'#009E8F'}} target={'_blank'}   href="http://www.youtube.com/c/Innovatsiyatexnologiyavastrategiyamarkaziitsm">
                                                   <FaYoutube/>
                                               </a>
                                           </p>
                                       </Col>
                                   </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    </Col>
                    <Col lg={16} md={24} sm={24}>
                        <div className="map">
                            <iframe src="https://maps.google.com/maps?q=41.322164,69.267404&hl=es&z=14&amp;output=embed" ></iframe>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Contact
)