import React from 'react';
import {Button, Col, Divider, Input, Modal, Radio, Row, Select, Steps,Form,message} from "antd";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {sign_up} from "../../../api/config/authentication";
import {getDistrictByRegionId, getDistricts, getRegions} from "../../../api/config/Servises";
import { Redirect } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


const {Step}=Steps
const {Option}=Select;
const {TextArea}=Input;

class ModalSignup extends React.Component {
    state = {
        visible: true,
        current: 0,
        regions:[],
        districts:[],
        user_data:{},
        uzLang:this.props.uz,
        enLang:this.props.en,
        jshshr:'',
        last_name:'',
        first_name:'',
        familyName:'',
        school: ""

    };

    onClose = () => {
        this.setState({
            visible: false
        })
        window.location.pathname="/home"
    };
    updateInputValue=(evt)=> {
        this.state.jshshr=evt.target.value

    }
    getLast=(evt)=> {
        this.state.last_name=evt.target.value

    }
    getFirst=(evt)=> {
        this.state.first_name=evt.target.value

    }
    getfamilyName=(evt)=> {
        this.state.familyName=evt.target.value
    }
    getorganizationName=(evt)=> {
        this.state.school=evt.target.value
    }
    getErp=()=>{
        axios.get(`https://api.oqituvchilar.uz/api/user/erp/${this.state.jshshr}`)
            .then(res=>{
                console.log(res.data.result)
                    if(res.data.result!=null){
                        document.getElementById('last_name').value=res.data.result.familyName;
                        document.getElementById('middle_name').value=res.data.result.lastName;
                        document.getElementById('first_name').value=res.data.result.firstName;
                        document.getElementById('school').value=res.data.result.organizationName;
                        this.state.familyName=res.data.result.familyName;
                        this.state.first_name=res.data.result.firstName;
                        this.state.last_name=res.data.result.last_name;
                        this.state.school=res.data.result.organizationName
                    }
                    else{

                        toast.error("Sizning ma'lumotlaringiz ERP ma'lumotlar bazasidan topilmadi")
                    }

            })

    }
    collections=()=>{
        getRegions().then((res)=>{
            if (res){
                this.setState({
                    regions: res.data
                });
            }
        });
        getDistricts().then((res)=>{
            if(res){
                this.setState({
                    districts:res.data,
                })
            }
        })
    }

    componentDidMount() {
        this.collections()
        this.setState({
            visible:true,
        })
    }

    render() {

        const {current,user_data,regions,districts,uzLang,enLang}=this.state

        const Finish = (values) => {

            this.setState({
                current:current+1,
            });
            let dd={
                ...user_data,
                school:this.state.school,
                districtId:values.district,
                birthday:new Date().getTime()
            };
            sign_up(dd).then(res=>{
                if (res.data.success){
                    toast.success(uzLang?"Ro'yxatdan o'tish muvaffaqiyatli amalga oshirildi!":"Регистрация прошла успешно!")
                    this.onClose()
                } else {
                    toast.error(uzLang?"Bu foydalanuvchi oldin ro'yxatdan o'tgan!":"При регистрации произошла ошибка!")
                }
            })
        };
        const Finish2 = (values) => {
            console.log(values)
            this.setState({
                current:current+1,
                user_data:{
                    ...user_data,
                    lastname:this.state.last_name,
                    firstname:this.state.first_name,
                    fathersName:this.state.familyName,
                    email:values.email,
                    // birthday:12345,
                }
            })
        };
        const Finish1 = (values) => {
            if(values.password===values.password1){
                this.setState({
                    current:current+1,
                    user_data:{
                        ...user_data,
                        username:values.login,
                        password:values.password,
                    }
                })

            }else{
                this.setState({
                    current:current,
                })
            }
        };
        const StepsChange=(current)=>{
            this.setState({
                current,
            })
        }
        const Select_Region=(value)=>{
            getDistrictByRegionId(value).then((res)=>{
                if (res){
                    this.setState({
                        districts: res.data
                    });
                }
            })

        }
    // const  onClose = () => {
        //     this.setState({
        //         visible:false
        //     })
        //     window.location.pathname="/home"
        // };
        return (
            <Modal
                footer={false}
                style={{
                    paddingBottom:'150px',
                }}
                title={<h3 style={{textAlign:'center'}}>{uzLang?"Ro'yxatdan o'tish":enLang?"Sign Up":"Регистрация"}</h3>}
                visible={this.state.visible}
                className={'Sign_up_modal'}
                onOk={Finish}
                onCancel={this.onClose}
            >
                <Steps labelPlacement={'vertical'} current={current} onChange={StepsChange} responsive={true}>
                    <Step description={<h3>{uzLang?"Profil ma'lumotlari":enLang?"Profile data":"Данные профиля"}</h3>}/>
                    <Step  description={<h3>{uzLang?"Shaxs malumotlari":enLang?"Your data":"Ваши данные"}</h3>}/>
                    <Step description={<h3>{uzLang?"Ish joyi":enLang?"Place of work":"Место работы"}</h3>} />
                </Steps>

                <Divider />
                <ToastContainer />
                {
                    current==0?
                        <Form
                            style={{
                                width:'100%',
                                position:'absolute'
                            }}
                            name="Add "
                            initialValues={{ remember: true }}
                            onFinish={Finish1}
                        >


                            <Form.Item
                                style={{
                                    marginLeft:'10%',
                                    width:'100%'
                                }}
                                name="login"
                                rules={[{ required: true, message: uzLang?"Loginingizni kiriting":enLang?"Enter login":" Введите логин" }]}
                            >
                                <Row><Col span={24}><div style={{width:'70%'}}><Input placeholder={uzLang?"Loginingizni kiriting":enLang?"Enter login":" Введите логин" }/></div></Col></Row>
                            </Form.Item>

                            <Form.Item
                                style={{
                                    marginLeft:'10%',
                                    width:'100%'
                                }}
                                name="password"
                                rules={[{ required: true, message: uzLang?"Parolni kiriting":enLang?"Enter password":"Введите пароль"}]}
                            >
                                <Row><Col span={24}><div style={{width:'70%'}}><Input.Password placeholder={uzLang?"Parolni kiriting":enLang?"Enter password":"Введите пароль"} className={'parol1'}/></div></Col></Row>
                            </Form.Item>
                            <Form.Item
                                style={{
                                    marginLeft:'10%',
                                    width:'100%'
                                }}
                                name="password1"
                                rules={[{ required: true, message: uzLang?"Parolni qayta kiriting":enLang?"Repeat password":"Повторите пароль" }]}
                            >
                                <Row>
                                    <Col span={24}><div style={{width:'70%'}}><Input.Password placeholder={uzLang?"Parolni qayta kiriting":enLang?"Repeat password":"Повторите пароль"} className={'parol2'}/></div></Col></Row>
                            </Form.Item>

                            <Row>
                                <Col md={4} xs={24}></Col>
                                <Col md={16} xs={24}>
                                <p className={'remember_password'} style={{width:'90%',textAlign:'center'}}>{uzLang?"Ro'yxatdan o'tish orqali siz bizning ":enLang?"By registering, you agree to our ":"Регистрируясь, вы соглашаетесь с нашими "}
                                            <span className={'to_sign_up'} style={{fontSize:'16px'}}>{uzLang?" Shartlarimiz va Maxfiylik siyosatimizga ":enLang?"Terms and Privacy Policy.":"Условия и политика конфиденциальности."}</span>{uzLang?" rozilik bildirasiz.":enLang?"":" ты согласен"}</p>

                                  </Col>

                            </Row>
                            <Form.Item>


                                <Col md={24} xs={24} style={{textAlign:'center'}}>
                                <div className="d-flex-center" style={{marginTop:'100px',marginLeft:'-5%'}}>
                                                         <button className="button mission-btn grands-btn" >

                                                         {uzLang?"Davom eting":enLang?"Proceed":"Продолжить"}
                                                             </button>

                                                    </div>
                                    {/* <button  htmlType="submit" hover={false} className={'button mission-btn grands-btn'}><h3 style={{textAlign:'center'}}>{uzLang?"Davom eting":enLang?"Proceed":"Продолжить"}</h3></button> */}
                                </Col>
                            </Form.Item>
                        </Form>
                        :current==1 ?

                            <Form
                                style={{
                                    width:'100%',
                                    position:'absolute'
                                }}
                                name="Add "
                                initialValues={{ remember: true }}
                                onFinish={Finish2}
                            >
                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'100%'
                                    }}
                                    name="jshshr"
                                    rules={[{ required: false, message: uzLang?"Jshshr kiriting":enLang?"Enter your jshshr":"Введите вашу пинфл" }]}
                                >
                                    <Row>
                                        <Col span={24}><div style={{width:'70%'}}>
                                        <Input type="text"  name="jshshr" onChange={this.updateInputValue}  placeholder={uzLang?"Jshshr kiriting":enLang?"Enter your jshshr":"Введите вашу пинфл" }/>
                                    </div>
                                            <div className="d-flex-center" style={{marginTop:'100px',marginLeft:'-5%'}}>
                                                <a onClick={this.getErp} className="button mission-btn grands-btn" >

                                                    {uzLang?"Qidirish":enLang?"Search":"Поиск"}
                                                </a>

                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Item>



                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'100%'
                                    }}
                                    name="fullname"
                                    rules={[{ required: false, message: uzLang?"Familiyangizni kiriting":enLang?"Enter your last name":"Введите вашу фамилию" }]}
                                >
                                    <Row><Col span={24}><div style={{width:'70%'}}>
                                        <Input onChange={this.getLast} id="last_name" placeholder={uzLang?"Familiyangizni kiriting":enLang?"Enter your last name":"Введите вашу фамилию" }/></div>
                                    </Col></Row>
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'100%'
                                    }}

                                    name="name"
                                    rules={[{ required: false, message: uzLang?"Ismingizni kiriting":enLang?"Enter your name":"Введите ваше имя" }]}
                                >
                                    <Row><Col span={24}><div style={{width:'70%'}}><Input onChange={this.getFirst} id="first_name" placeholder={uzLang?"Ismingizni kiriting":enLang?"Enter your name":"Введите ваше имя" }/></div></Col></Row>
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'100%'
                                    }}
                                    name="fathername"
                                    rules={[{ required: false, message: uzLang?"Otangizning ismini kiriting":enLang?"Enter your father's name":"Введите имя вашего отца" }]}
                                >
                                    <Row><Col span={24}><div style={{width:'70%'}}><Input onChange={this.getfamilyName} id="middle_name" placeholder={uzLang?"Otangizning ismini kiriting":enLang?"Enter your father's name":"Введите имя вашего отца" }/></div></Col></Row>
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'100%'
                                    }}
                                    name="email"
                                    rules={[{ required: true, message: uzLang?"Emailingizni kiriting":enLang?"Enter your email":"Введите адрес электронной почты" }]}
                                >
                                    <Row><Col span={24}><div style={{width:'70%'}}><Input type={'email'} placeholder={uzLang?"Emailingizni kiriting":enLang?"Enter your email":"Введите адрес электронной почты" }/></div></Col></Row>
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'100%'
                                    }}
                                    name="school"
                                    rules={[{ required: false, message: 'school' }]}
                                >
                                    <Row><Col span={24}><div style={{width:'70%'}}>
                                        <Input  onChange={this.getorganizationName} id="school" placeholder={uzLang?"Maktabingiz raqami yoki nomini kiriting":enLang?"Choose a school":"Введите номер или название вашей школы"}/></div></Col></Row>
                                </Form.Item>



                                <Row>
                                    <Col md={4} xs={24}></Col>
                                    <Col md={16} xs={24}>
                                    <p className={'remember_password'} style={{width:'90%',textAlign:'center'}}>{uzLang?"Ro'yxatdan o'tish orqali siz bizning ":enLang?"By registering, you agree to our ":"Регистрируясь, вы соглашаетесь с нашими "}
                                            <span className={'to_sign_up'} style={{fontSize:'16px'}}>{uzLang?" Shartlarimiz va Maxfiylik siyosatimizga ":enLang?"Terms and Privacy Policy.":"Условия и политика конфиденциальности."}</span>{uzLang?" rozilik bildirasiz.":enLang?"":" ты согласен"}</p>

                                  </Col>

                                </Row>
                                <Form.Item>

                                    <Col md={24} xs={24} style={{textAlign:'center'}}>
                                    <div className="d-flex-center" style={{marginTop:'100px',marginLeft:'-5%'}}>
                                                         <button className="button mission-btn grands-btn" >

                                                         {uzLang?"Davom eting":enLang?"Proceed":"Продолжить"}
                                                             </button>

                                                    </div> </Col>
                                </Form.Item>
                            </Form> :

                            <Form
                                style={{
                                    width:'100%',
                                    position:'absolute'
                                }}
                                name="Add "
                                initialValues={{ remember: true }}
                                onFinish={Finish}
                            >



                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'80%'
                                    }}
                                    name="region"
                                    rules={[{ required: true, message: uzLang?"Hududni tanlang":enLang?"Choose region":"Выберите регион" }]}
                                >
                                    <Select  onChange={Select_Region}  defaultValue={uzLang?"Hududni tanlang":enLang?"Choose region":"Выберите регион"}>


                                        {
                                            regions&&regions.map(item=>{
                                                return <Option value={item.id}>{uzLang?item.nameUz:item.nameRu}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'80%'
                                    }}
                                    name="district"
                                    rules={[{ required: true, message: 'district' }]}
                                >
                                    <Select defaultValue={uzLang?"Tumanni tanlang":enLang?"Select area":"Выберите район"}>
                                        {
                                            districts&&districts.map(item=>{
                                                return <Option value={item.id}>{uzLang?item.nameUz:item.nameRu}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    style={{
                                        marginLeft:'10%',
                                        width:'80%'
                                    }}
                                    name="agree"
                                    rules={[{ required: false, message: 'agree' }]}>
                                    <Radio value={'Ha'}>{uzLang?"Siz uyushma a’zosimisiz?":enLang?"Are you a member of the association?":"Являетесь ли вы участником ассоциации?"} </Radio>
                                </Form.Item>



                                <Row>
                                    <Col md={4} xs={24}></Col>
                                    <Col md={16} xs={24}>
                                    <div style={{width:"100%",overflow:"hidden",padding:'10px'}}>
                                        <p className={'remember_password'} style={{width:'90%',textAlign:'center'}}>{uzLang?"Ro'yxatdan o'tish orqali siz bizning ":enLang?"By registering, you agree to our ":"Регистрируясь, вы соглашаетесь с нашими "}
                                            <span className={'to_sign_up'} style={{fontSize:'16px'}}>{uzLang?" Shartlarimiz va Maxfiylik siyosatimizga ":enLang?"Terms and Privacy Policy.":"Условия и политика конфиденциальности."}</span>{uzLang?" rozilik bildirasiz.":enLang?"":" ты согласен"}</p>

                                    </div>
                                    </Col>

                                </Row>
                                <Form.Item>

                                    <Col span={24} style={{textAlign:'center'}}>
                                    <div className="d-flex-center" style={{marginTop:'100px',marginLeft:'-5%'}}>
                                                         <button className="button mission-btn grands-btn" >

                                                         {uzLang?"Davom eting":enLang?"Proceed":"Продолжить"}
                                                             </button>

                                                    </div>  </Col>
                                </Form.Item>

                            </Form>
                }

            </Modal>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ModalSignup
)
