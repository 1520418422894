import React from 'react';
import {Card, Col, Row,Upload,Tooltip,message} from "antd";
import {CameraOutlined} from '@ant-design/icons';
import {FaUserCircle} from "react-icons/all";
import { getMe } from '../../../api/config/authentication';
import {deleteCookie, getCookie} from '../../../utils/useCookies';
import { userAccessTokenName } from '../../../api/constants';
import {connect} from "react-redux";
import {host, port} from "../../../api/host";
export const token = getCookie(userAccessTokenName);

class ProfileItem extends React.Component{
    state={
        current_user:null,
        avatarId:null,
    }
    componentDidMount() {

        getMe().then(res => {
            if (res && res.data) {
                console.log(res.data);
                this.setState({
                    current_user: res.data,
                    avatarId:res.data.avatarId,
                })
            } else {
                deleteCookie(userAccessTokenName)
            }
        });
       
    }


    render() {
        const {current_user,avatarId}=this.state;
        const {uz,en}=this.props;
        const props = {
            name: 'file',
            method:"put",
            action: `${host}:${port}/api/user/editProfilImage`,
            headers: {
                'Authorization': token ?`Bearer ${token}`:''
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    window.location.reload()
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <div className="item-area">
                    <div className="title-page">
                        <p className="big-title-route">
                            
                            {uz?"Profil":"Профиль"}
                        </p>

                    </div>
                    <Row>
                        <Col md={12} sm={24}>
                            <Card style={{padding:'20px',textAlign:'center',borderRadius:'20px'}}>
                             <div className="user">
                                 <Tooltip
                                     placement={'right'}
                                     title={
                                         <Upload {...props}>
                                    <CameraOutlined style={{fontSize:'20px',cursor:'pointer',color:'white'}}/>
                                </Upload>}>

                                     {
                                         avatarId? <img className={'avatar-img'} src={host+':'+port+'/api/auth/file/'+avatarId} alt=""/>:<FaUserCircle style={{cursor:'pointer'}}/>
                                     }

                                </Tooltip></div>
                                
                            </Card>

                            <p style={{color:'grey',fontSize:'20px'}}>
                                {
                                    uz?"Otasining ismi":"Отчество"
                                }
                            </p>
                            <input type="text" className="profile-input" value={current_user&&current_user.fathersname}/>  <p style={{color:'grey',fontSize:'20px'}}>
                            {
                                uz?"Maktab turini tanlang":"Выберите тип школы "
                            }
                        </p>
                            <input type="text" className="profile-input"/>
                            <p style={{color:'grey',fontSize:'20px'}}>
                                {
                                    uz?"Mavzuni o'qitish":"Предмет преподавание"
                                }
                            </p>
                            <input type="text" className="profile-input" value={uz?"Ingliz tili":"английский язык"}/>

                        </Col>
                        <Col md={12} sm={24}>
                            <div className="p10">
                                <p style={{color:'grey',fontSize:'20px'}}>
                                    {
                                        uz?"Ism":"Имя"
                                    }
                                </p>
                                <input type="text" className="profile-input" value={current_user&&current_user.firstname}/>
                                <p style={{color:'grey',fontSize:'20px'}}>
                                    {
                                        uz?"Familiya":"Фамилия"
                                    }
                                </p>
                                <input type="text" className="profile-input" value={current_user&&current_user.lastname}/>
                                <p style={{color:'grey',fontSize:'20px'}}>
                                {
                                    uz?"Tug'ilgan kuningiz":"Дата рождения"
                                }
                                </p>
                                <input type="text" className="profile-input" value={current_user&&current_user.birthday}/>
                                <p style={{color:'grey',fontSize:'20px'}}>
                                    {
                                        uz?"Maktab raqami":"Номер школы"
                                    }
                                </p>
                                <input type="text" className="profile-input" value={current_user&&current_user.school}/>
                                <p style={{color:'grey',fontSize:'20px'}}>
                                    {
                                        uz?"Pedagogik tajriba":"Педагогический стаж"
                                    }
                                </p>
                                <input type="text" className="profile-input" value={uz?"7 yil":"7 лет"}/>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{})(
    ProfileItem
)