import {
    GetEnLanguage,
    SetLocalstorage,
    GetLanguage,
} from "../../utils/utils";
const initialState = {
    uz: true,
    en:false,
};

export const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case "uz":

            SetLocalstorage("news_project","UZ");
            return {
                uz: true,
                en:false,
            };
        case "en":

            SetLocalstorage("news_project","EN");
            return {
                uz: false,
                en:true,
            };
        case "ru":

            SetLocalstorage("news_project","RU");
            return {
                uz: false,
                en:false,
            };

        default:

        {GetLanguage()?SetLocalstorage("news_project","UZ"):!GetEnLanguage()?SetLocalstorage("news_project","EN"):SetLocalstorage("news_project","Ru")}
            return {
                uz:false,
                en:true
            };
    }
};
