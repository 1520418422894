import React from 'react';
import {
    Row,
    Col, Card,
} from 'antd';
import '../../styles/global.css';
import './consultation.css'
import {
    Link
} from 'react-router-dom';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import Slider from "react-slick";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getConsultants} from "../../api/config/Servises";
import {host, port} from "../../api/host";

class Consultation extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.state={
            consultants:[],
        }
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    componentDidMount() {
        getConsultants(0,6).then(res=>{
            if(res&&res.data){
                this.setState({
                    consultants:res.data,
                })
            }
        }).catch(error=>{
            console.log(error)
        })
    }

    render() {
        const {uz,en}=this.props;
        const {consultants}=this.state;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:true,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],

        };
        return (
            <>
                <HorizontalMenu/>
                <div className="faq-page">

                    <div className="container">
                        <div className="title-page">
                            <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>   > <span className="mainColor">{uz?"Konsultatsiya":"Консультация"}</span></p> <br/>

                        </div>
                        <div className="title-page">
                            <p className="big-title-route">
                                {uz?"Konsultatsiya":"Консультация"}
                            </p>



                        </div>
                        <p className={'fs-fw'}>
                            {uz?"Portalning ro‘yxatdan o‘tgan a‘zolari tajribali maslahatchilardan huquqiy, moliyaviy va boshqa masalalar bo‘yicha har tomonlama yordam, maslahat va ma‘lumot olishlari mumkin":"Зарегистрированные члены портала могут получить посильную поддержку, консультацию и информацию по юридическим, финансовым и другим вопросам у опытных консультантов."}

                        </p>
                        <Slider {...settings}>
                            {
                                consultants&&consultants.map(item=>{
                                    return <div className={'slider-item'}>
                                        <Row>
                                            <Col md={24} sm={24} xs={24}>
                                                <div className="consultation-carousel">
                                                    <Card className={"consultation-carousel-card"}>
                                                        <div className="avatar-and-tags">
                                                            <Row>
                                                                <Col span={24}>
                                                                    <div className="img1">
                                                                        <img src={host+':'+port+'/api/auth/file/'+item.avatar.id} alt=""/>
                                                                    </div>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <p className="consultation-title">
                                                                        {uz?item.fullnameUz:item.fullnameRu}
                                                                    </p>
                                                                    {/*<p className="w100 fs-fw" style={{color:'grey'}}>*/}
                                                                    {/*    {uz?"#mavzu #o‘zbek tili #adabiyot":"#предмет #узбекскийязык #литература "}*/}
                                                                    {/*</p>*/}
                                                                </Col>
                                                                <Col span={24}>
                                                                    <div className="p10 consultation-carousel-little-text">
                                                                        {uz?item.aboutUz:item.aboutRu}

                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                        <Link to={'/consultations/'+item.id}>
                                                            <div className="d-flex-center">
                                                                <button className="button consultation-btn ">
                                                                    {uz?"Konsultatsiya olish":"Получить консультацию"}
                                                                </button>
                                                            </div>
                                                        </Link>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                })
                            }

                        </Slider>
                    </div>


                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Consultation
)