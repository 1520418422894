import React from 'react';
import './forum.css';
import {
    Row,
    Col,
    Select,
    Button,
    Modal,
    Form,
    Input,
} from 'antd';
import '../../styles/global.css';
import {
    FaDiscourse,
}from 'react-icons/fa';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getMe} from "../../api/config/authentication";
import {deleteCookie} from "../../utils/useCookies";
import {userAccessTokenName} from "../../api/constants";
import {getForumCategories, getForums, postForum} from "../../api/config/Servises";
const {Option}=Select;
class Forum extends React.Component {
    state={
        current_user:null,
        forumCategories:[],
        

    }
    componentDidMount() {
       
        getMe().then(res => {
            if (res && res.data) {
                this.setState({
                    current_user: res.data
                })
            } else {
                deleteCookie(userAccessTokenName)
            }
        });
        getForumCategories().then(res=>{
            if (res&&res.data) {
                this.setState({
                    forumCategories:res.data,
                })
            }
        }).catch(res=>{
            console.log('error');
        })
        // getForums(0,10).then(res=>{
        //     if (res&&res.data&&res.data.content) {
        //     }
        // })

    }

    render() {
        const {uz,en}=this.props;
        const {current_user,forumCategories}=this.state;
        const onChange=(category)=>{


        }



        return (
            <>
                <HorizontalMenu/>
                <div className="faq-page">
                    <div className="container">
                        <div className="title-page">
                            <p className="title-route" style={{width:'100%'}}><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa ":"Главная"}</Link>   > <span className="mainColor">{uz?"Forum":"Форум"}</span></p> <br/>
                            <p className="big-title-route forum-title forum">
                                {uz?"Forum":"Форум"}
                            </p>
                        </div>
                    </div>

                    {
                        forumCategories&&forumCategories.map(item=>{
                            return  <div className="questions-page">
                            <div className="page-title-lenta">
                                <div className="container">
                                    <Row>
    
                                        <Col span={22}>
                                            <Link to={'/forum/'+item.id}>
                                                <div className="question" style={{fontSize:'22px'}}>
                                                    {uz?item.titleUz:item.titleRu}
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
    
                                </div>
                            </div>
                            <div className="container " style={{marginBottom:'20px'}}>
                                <div className="p10">
                                    <Row>
                                        <Col span={2}>
                                            <div className="discuss discuss-color-before" style={{marginTop:'15px'}}>
                                                <FaDiscourse/>
                                            </div>
                                        </Col>
                                        <Col span={18}>
                                            <div className="question ">
    
                                                <p style={{color:'grey'}}>
                                                    {uz?item.descriptionUz:item.descriptionRu}</p>
                                            </div>
                                        </Col>
    
                                    </Row>
                                </div>
    
                            </div>
    
                        </div>
                        })
                    }
                



                </div>
               

            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Forum
)
