import React from 'react';
import './../contests.css';
import {
    Row,
    Col,
} from 'antd';
import {
    FaArrowRight,
} from 'react-icons/fa';
import {
    Link
} from "react-router-dom";
import {getContests} from "../../../api/config/Servises";
import {host, port} from "../../../api/host";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {month} from "../../../constants/month";
import Loader from "../../../commonComponents/loader/loader";

class All_contests extends React.Component{


    render() {
        const {uz,en,contests,MoreContests}=this.props;


        return (
            <div>
                {
                    this.props.loading?<Loader/>:
                        <div className="all-contests">
                            {
                                contests?contests.map(function (item) {
                                    var reg=new Date(item.registrationAt);
                                    var start=new Date(item.startAt);
                                    var end=new Date(item.endAt)
                                    return <div className="contest" data-aos={'zoom-in'}>
                                        <Row>
                                            <Col md={8} sm={24}>
                                                <div className="w100 contest-img p10">
                                                    <img src={host+':'+port+'/api/auth/file/'+item.logo.id} alt=""/>
                                                </div>
                                            </Col>
                                            <Col md={16} sm={24}>
                                                <div className="w100 p10">
                                                    <Link to={'/contests/'+item.id}>
                                                        <h2 className="fs-fw contest-title">
                                                            {uz?item.titleUz:item.titleRu}
                                                        </h2>
                                                    </Link>

                                                    <Row>
                                                        <Col md={12} sm={24}>
                                                            <p className="mainColor fs-fw" style={{fontWeight:700}}>
                                                                {uz?"Ro'yxatdan o'tish muddati":" Pегистрация до"} : <br/> {reg.getDate()}-{uz?month[reg.getMonth()].titleUz:month[reg.getMonth()].titleRu} {reg.getFullYear()}-{uz?"yil":"год"}
                                                            </p>
                                                        </Col>
                                                        <Col md={12} sm={24}>
                                                            <p className="mainColor fs-fw" style={{fontWeight:700}}>

                                                                {uz?"Boshlanish vaqti":" Hачало в"} : <br/> {start.getDate()}-{uz?month[start.getMonth()].titleUz:month[start.getMonth()].titleRu} {start.getFullYear()}-{uz?"yil":"год"}
                                                            </p>
                                                        </Col>
                                                        <Col span={24}>
                                                            <p className="fs-fw contest_card-desc" style={{fontWeight:700,color:'grey'}} dangerouslySetInnerHTML={{__html:uz?item.contentUz:item.contentRu}}/>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="right-arrow-icon">
                                            <Link to={'/contests/'+item.id}> <FaArrowRight/></Link>
                                        </div>
                                    </div>
                                }):""
                            }


                            <div className="d-flex-center">
                                <button className="button mission-btn news-btn" onClick={MoreContests}>
                                    {uz?"Ko'proq ko'rsatish":"Показать еще"}
                                </button>
                            </div>
                        </div>
                }
            </div>
        );
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    All_contests
)