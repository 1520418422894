import React from 'react'
import './App.css';
import './styles/global.css';
import 'antd/dist/antd.css';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import {routes} from "./routes/routes";
import {
  Row,
  Col,
} from "antd";
import Footer from "./commonComponents/footer/footer";
import {
    GetLanguage,
    GetEnLanguage,
    GetRuLanguage,
} from "./utils/utils";
import {enLanguage} from "./redux/actions/EnLanguage";
import {uzLanguage} from "./redux/actions/Uzlanguage";
import {ruLanguage} from "./redux/actions/RuLanguage";
import {connect} from "react-redux";
import {getMe} from "./api/config/authentication";
import {deleteCookie} from "./utils/useCookies";
import {userAccessTokenName} from "./api/constants";
import Loader from "./commonComponents/loader/loader";
import {getMainSliders} from "./api/config/Servises";
import HomeLoader from './commonComponents/loader/home_loader';

class App extends React.Component{
    state = {
        current_user: null,
        loading: true,
    }
    demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 0));
    }
    componentDidMount() {
        getMe().then(res => {
            if (res && res.data) {
                this.setState({
                    current_user: res.data
                })
            } else {
                deleteCookie(userAccessTokenName)
            }
        });
        getMainSliders().then(res=>{
            if (res){
                this.setState({
                    loading:false,
                })
            }
        })

        window.scrollTo(0,0)
        if(GetLanguage()){
            this.props.uzLanguage()
        }else{
            this.props.ruLanguage()
        }

        getMainSliders().then(res=>{
            if(res &&res.data){
                this.setState({ loading: false })
            }
        });

    }
  render() {
    return (
        <div style={{width:'100%',overflowX:'hidden'}}>

            {
                this.state.loading?<HomeLoader/>:
                    <Router>
                    <Switch>
                        <Row>
                            {
                                routes.map((item) => (
                                    <Col span={24}>
                                        <Route exact path={item.path} component={item.component}/>
                                    </Col>

                                ))
                            }

                        </Row>


                        <Redirect from="/" to="/join"/>
                    </Switch>
                    <Footer/>
                </Router>
            }

        </div>
    )
  }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    App
)
