import HomePage from "../pages/home/home";
import Forum from "../pages/forum/forum";
import Contests from "../pages/contests/contests";
import News from "../pages/home/components/news/news";
import Resources from "../pages/resources/resources";
import Faq from "../pages/faq/faq";
import News_by_id from "../pages/news/news_by_id";
import Contest_by_id from "../pages/contests/contest_by_id";
import SidebarMenu from "../commonComponents/sidebarMenu/sidebarMenu";
import All_News from "../pages/news/news";
import Consultation from "../pages/consultation/consultation";
import About from "../pages/about/about";
import Profile from "../pages/profile/profile";
import Gallery_by_id from "../pages/about/gallery/gallery_by_id";
import Join from "../pages/joinassociation/join";
import ConsultationById from "../pages/consultation/consultationById";
import ForumByCategory from "../pages/forum/forumsByCategory";
import ForumByQuestion from "../pages/forum/forumByQuestion";
import ModalSignup from "../commonComponents/horizontalMenu/components/ModalSignup";

export const routes=[
    {
        path:'/home',
        component: HomePage,
    },{
        path:'/',
        component: Join,
    },  {
        path:'/forum',
        component: Forum,
    }, {
        path:'/forum/:id',
        component: ForumByCategory,
    }, {
        path:'/questions/:id',
        component: ForumByQuestion,
    },{
        path:'/contests',
        component: Contests,
    },{
        path:'/consultations',
        component: Consultation,
    },{
        path:'/consultations/:id',
        component: ConsultationById,
    },{
        path:'/news',
        component: All_News,
    },{
        path:'/resources',
        component: Resources,
    },{
        path:'/faq',
        component: Faq,
    },{
        path:'/news/:id',
        component: News_by_id,
    },{
        path:'/contests/:id',
        component: Contest_by_id,
    },
    {
        path:'/sidebar-menu',
        component: SidebarMenu,
    },
    {
        path:'/join',
        component: Join,
    },
    {
        path:'/about/:id',
        component: About,
    },
    {
        path:'/profile',
        component: Profile,
    },
    {
        path:'/gallery/:id',
        component: Gallery_by_id,
    },
    {
        path:'/sign-up',
        component: ModalSignup
    }
];
