import React from 'react';
import './footer.css';
import {
    Row,
    Col, Menu, Dropdown,
} from 'antd';
import '../../styles/global.css';
import {
    FaPhoneAlt,
    FaInstagram,
    FaTelegramPlane,
    FaFacebookF,
    FaYoutube,
    FaGooglePlusG,
} from 'react-icons/fa';
import {
    Link
} from 'react-router-dom';
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";

class Footer extends React.Component{
    render() {
        const {uz,en}=this.props;
        const about_proect = (
            <Menu className={'menu-dropdown'} style={{marginTop:'0'}}>
                <Menu.Item>

                    <Link to={'/about/our-mission'} ><p style={{fontWeight:'700',fontSize:'16px'}}> {uz?"Maqsadimiz":en?"":"НАША МИССИЯ"}</p></Link>


                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/about/gallery'}><p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Galereya":en?"":"ГАЛЕРЕЯ"}</p></Link>

                </Menu.Item>
                <Menu.Item>

                    <Link to={'/about/compositions'}><p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Bizning jamoa":en?"":"НАШ СОСТАВ"}</p></Link>



                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/about/contact'}> <p  style={{fontWeight:'700',fontSize:'16px'}}>{uz?"Biz bilan bog’lanish":en?"":"КОНТАКТЫ"}</p></Link>

                </Menu.Item>
            </Menu>
        );
        const forum_list = (
            <Menu  style={{marginTop:'0'}} className={'menu-dropdown'}>
                <Menu.Item>

                    <Link to={'/forum'} ><p style={{fontWeight:'700',fontSize:'16px'}}>{uz?"FORUM":en?"":"ФОРУМ"}</p></Link>


                </Menu.Item>
                <Menu.Item  >

                    <Link to={'/faq'}><p  style={{fontWeight:'700',fontSize:'16px'}}>FAQ</p></Link>

                </Menu.Item>

            </Menu>
        );
        return(
            <>
                <div className="footer-page">
                    <Row>
                        <Col md={4} sm={8} xs={24}>
                            <div className="d-flex">
                                <p className="mainColor w100 footer-first-text">
                                    {uz?"“O‘zbekistonda Mustaqil o‘qituvchilar assotsiatsiyalari imkoniyatlarini kengaytirish” loyihasi doirasidagi o‘qituvchilar va assotsiatsiyalar uchun platforma.":"Платформа для учителей и ассоциаций, созданная в рамках проекта «Расширение возможностей независимых ассоциаций учителей в Узбекистане»"}
                                    </p>
                            </div>
                        </Col>
                        <Col md={8} sm={16} xs={24}>
                            <img src="/images/footer_images.PNG" alt="" className="w100" style={{marginTop:'20px'}}/>
                        </Col>
                        <Col md={4} sm={8} xs={24}>
                            <div className="footer-ul">
                                <ul>

                                    <Link to={'/about/mission'}>
                                        <Dropdown trigger='click' overlay={about_proect}>
                                        <li className="mainColor"> {uz?"Loyiha haqida":"О проектe"}</li>
                                        </Dropdown>
                                    </Link>

                                        <Link to={'/forum'}>
                                            <Dropdown trigger='click' overlay={forum_list}>
                                                <li className="mainColor"> {uz?"Forum":"Форум"}</li>
                                            </Dropdown>
                                        </Link>
                                    <Link to={'/contests'}>

                                            <li className="mainColor"> {uz?"Tanlovlar":"Конкурсы"}</li>

                                        </Link>
                                     <Link to={'/news'}><li className="mainColor"> {uz?"Yangiliklar":"Новости"}</li></Link>
                                </ul>
                            </div>
                        </Col>
                        <Col md={4} sm={8} xs={24}>

                            <div className="footer-ul">
                                <ul>
                                    <Link to={'/resources'}><li className="mainColor"> {uz?"Manbalar":"Ресурсы"}</li></Link>
                                    <Link to={'/consultations'}><li className="mainColor"> {uz?"Konsultatsiya":"Консультация"}</li></Link>
                                    <Link to={'/faq'}><li className="mainColor">FAQ</li></Link>
                                </ul>
                            </div>
                        </Col>
                        <Col md={4} sm={8} xs={24}>
                            <Row>
                                <Col span={4}>
                                    <span className="mainColor fs-fw"><FaPhoneAlt/></span>
                                </Col>
                                <Col span={20}><span className="mainColor fs-fw">+998 71 202 09 09</span></Col>
                                <Col span={4}> <span className="mainColor fs-fw"><FaGooglePlusG/></span></Col>
                                <Col span={20}><span className="mainColor fs-fw">info@itsm.uz </span></Col>
                                <Col span={24}>
                                    <div className="social-media">
                                        <Row>
                                            <Col span={6}>
                                                <div className="w100 social-media-icons">
                                                    <a target={'_blank'} style={{color:'#009E8F'}} href="http://instagram.com/itsm_uz"><FaInstagram/></a>
                                                </div>
                                            </Col>
                                            <Col span={6}>

                                                <div className="w100 social-media-icons">
                                                    <a target={'_blank'} style={{color:'#009E8F'}} href="https://t.me/itsmuzb">
                                                        <FaTelegramPlane/>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col span={6}>

                                                <div className="w100 social-media-icons">
                                                    <a target={'_blank'} style={{color:'#009E8F'}} href="https://www.facebook.com/itsc.uz/">
                                                        <FaFacebookF/>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col span={6}>

                                                <div className="w100 social-media-icons">
                                                    <a target={'_blank'}  style={{color:'#009E8F'}}  href="http://www.youtube.com/c/Innovatsiyatexnologiyavastrategiyamarkaziitsm">
                                                        <FaYoutube/>
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Footer
)