import React from 'react';
import './../resources.css';
import {
    Row,
    Col,
    Select,
} from 'antd';
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";

import {getVideoLessons} from "../../../api/config/Servises";
import {host, port} from "../../../api/host";


const { Option } = Select;

class Video_lessons extends React.Component {

    state={
        news:[],
        size:100,
        loading:true,
        page:0
    }

    myFunc(){
        getVideoLessons(0,100)
            .then(res=>{
                console.log(this.state)
                if (res&&res.data&&res.data.content){
                    console.log(res.data)
                }

            })
    }
    componentDidMount() {
        getVideoLessons(0,this.state.size).then(res=>{
            if (res && res.data&&res.data.content){
                this.setState({
                    news:res.data.content,
                    loading:false,
                })


            }
        }).catch(res=>{
            console.log('error')
        })


    }

    render() {
        const {
            news,
            size,
        }=this.state;
        const {uz,en}=this.props;
        const More_news=()=>{
            this.setState({
                size:size+3,
            })
            getVideoLessons(0,size+3).then(res=>{
                if (res&&res.data&&res.data.content){
                    this.setState({
                        news: res.data.content
                    })
                }
            })
                .catch(res=>{
                console.log('error')
            })
        }


        return (
            <>
                <div className="videos_page">
                    <div className={"select_area"}>
                        <div>
                            <Select defaultValue="lucy" style={{ width: 120 }} onChange={''}>
                            <Option value="jack">{uz?"Eng mashhur":"Популярные"}</Option>
                                <Option value="lucy">{uz?"Eng so'nggi":"Последние"}</Option>
                                <Option value="Yiminghe">{uz?"Eng yangi":"Новые"}</Option>


                            </Select>
                        </div>
                    </div>

                    <Row>
                        {
                            news?news.map(function (item) {
                                return   <Col md={8} sm={12} xs={24}>
                            <div className="videos-card"  data-aos={'zoom-in'}>
                                <div className="w75">
                                    <div className="img-and-video">
                                         <video controls className={'w100'}>
                                            <source src={host+':'+port+'/api/auth/file/'+item.video.id}/>
                                        </video>
                                    </div>
                                    <p className={'video-title'}>
                                        {uz?item.titleUz:item.titleRu}
                                    </p>
                                </div>
                            </div>
                        </Col>
                            }):""
                        }
                    </Row>


                    {/*<div className="d-flex-center">*/}
                    {/*    <button className="button mission-btn news-btn" onClick={this.myFunc}>*/}
                    {/*        {uz?"Ko'proq ko'rsatish":"Показать еще"}*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Video_lessons
)
