import React from 'react';
import {Card, Col, Row} from "antd";
import './join.css';
import FirstPage from "./components/FirstPage";
import SecondPage from "./components/SecondPage";
import ThirdPage from "./components/ThirdPage";
import FormsPage from "./components/FormsPage";
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import AboutProject from "./components/AboutProject";

class Join extends React.Component{
    render() {
        return (
            <div>
                <HorizontalMenu/>
                <AboutProject/>
                <FirstPage/>
                <SecondPage/>
                <ThirdPage/>
                <FormsPage/>
            </div>
        );
    }
}
export default Join