import React from 'react';
import {Card, Col, Row} from "antd";
import '../join.css';
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";

class FirstPage extends React.Component{
    render() {
        const {uz,en}=this.props;
        return (
            <div className={'p10'}>
                <div className="page-title-content ">
                    <div className="title-content">
                        <p className="home-page-title">
                            {uz?"IMKONIYATLARINGIZNI BIRLASHTIRIB, YANADA KO‘PIGA ERISHING!":"ОБЪЕДИНЯЙТЕ УСИЛИЯ, ЧТОБЫ ДОСТИЧЬ БОЛЬШЕГО!"}
                        </p>
                    </div>
                    <div className="title-content">
                        <span className="title-bottom-linear"></span>
                    </div>
                    <p className="mainColor little-text" >
                       {uz?"M":"С"}<span style={{textTransform:'lowercase'}}>{uz?"USTAQIL ASSOTSIATSIYALAR TASHKIL ETIB, QUYIDAGI NATIJALARGA ERISHING:":"ОЗДАВАЙТЕ НЕЗАВИСИМЫЕ ОБЪЕДИНЕНИЯ И ДОСТИГАЙТЕ СЛЕДУЮЩИХ РЕЗУЛЬТАТОВ:"}</span> 
                    </p>

                </div>
                <div className="container ">
                    <Row>
                        <Col md={12} sm={24} xs={24}  data-aos={'fade-right'}>
                            <img src="/images/as1.jpg" alt="" className="w100"/>
                        </Col>
                        <Col md={12} sm={24} xs={24} className={'p10'}>
                          <div className="p10"  data-aos={'fade-left'}>
                              <Row>
                                  <Col span={4}>
                                      <div className="number">
                                          <p>
                                              1
                                          </p>
                                      </div>
                                  </Col>
                                  <Col span={20}>
                                      <div className="counter-text">
                                          <p>
                                              {uz?"Jamiyatda ustozlar nufuzini oshirish va ijtimoiy ximoya qilish":"Повышение авторитета учителей в обществе и их социальная защита"}
                                          </p></div>
                                  </Col>
                              </Row>
                          </div>
                            <div className="p10" data-aos={'fade-left'}>
                                <Row>
                                    <Col span={4}>
                                        <div className="number">
                                            <p>
                                                2
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <div className="counter-text">
                                            <p> {uz?"O‘qituvchilarning huquqiy savodxonligini oshirish":"Повышение правовой грамотности учителей"}</p></div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="p10" data-aos={'fade-left'}>
                                <Row>
                                    <Col span={4}>
                                        <div className="number">
                                            <p>
                                                3
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <div className="counter-text">
                                            <p>{uz?"Ustozlarning malakasi va kasbiy salohiyatini oshirish":"Повышение квалификации и профессионального потенциала учителей"}</p></div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="p10"  data-aos={'fade-left'}>
                                <Row>
                                    <Col span={4}>
                                        <div className="number">
                                            <p>
                                                4
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <div className="counter-text">
                                            <p>{uz?"Yosh ustozlarni qo‘llab-quvvatlash (ustoz-shogird an’anasi bo‘yicha)":"Поддержка молодых учителей (в традициях учитель-ученик)"}</p></div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="p10" data-aos={'fade-left'}>
                                <Row>
                                    <Col span={4}>
                                        <div className="number">
                                            <p>
                                                5
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <div className="counter-text">
                                            <p>{uz?"Ustozlarning innovatsion g‘oyalarini ilgari surish":"Продвижение инновационных идей учителей"}</p></div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    FirstPage
)