import React from 'react';
import './loader.css';

import {Spin} from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
class Loader extends  React.Component{
    render() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
        return (
            // <div className='main-loader'>
            //     <div id="app" className="loader"></div>
            // </div>
            <div class={'main-loader'}>
               <Spin style={{maginLeft:'45%',fontSize:'30px'}} indicator={antIcon} />
   
            </div>
        );
    }
}
export default Loader