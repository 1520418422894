import React from 'react';
import './contests.css';
import {
    Row,
    Col,
} from 'antd';
import '../../styles/global.css';
import {
    FaDiscourse,
    FaCalendarAlt,
}from 'react-icons/fa';
import { Tabs } from 'antd';
import All_contests from "./all/all";
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getContestCategories, getContests, getContestsByCategoryId} from "../../api/config/Servises";
const { TabPane } = Tabs;

class Contests extends React.Component {
    state={
        contest_category:[],
        contests:[],
        size:3,
        categoryId:'',
        loading:true,
    }
    componentDidMount() {
        getContestCategories().then(res=>{
            if (res&&res.data){
                this.setState({
                    contest_category:res.data,
                })

            }
        })
        getContests(0,this.state.size).then(res=>{
            if (res&&res.data){
                this.setState({
                    contests:res.data.content,
                    loading:false,
                })
            }
        }).catch(res=>{
            console.log('error')
        })
    }



    render() {
        const {uz,en}=this.props;
        const {contest_category,contests,size}=this.state;
        const MoreContests=()=>{
            this.setState({
                size:size+3,
            })
            getContests(0,size+3).then(res=>{
                if (res&&res.data&&res.data.content){
                    this.setState({
                        contests: res.data.content
                    })
                }
            }).catch(res=>{
                console.log('error')
            })
        }
        const callback=(id)=>{
            this.setState({
                categoryId:id,
                size:3,
                loading:true,
            })
            if(id==='1'){
                getContests(0,this.state.size).then((res)=>{
                    if(res){
                        this.setState({
                            contests:res.data.content,
                            loading:false,
                        })
                    }
                })
            }else{
                getContestsByCategoryId(id,0,this.state.size).then((res)=>{
                    if(res){
                        this.setState({
                            contests:res.data.content,
                            loading:false,
                        })
                    }
                })
            }
        }
        return (
            <><HorizontalMenu/>
                <div className="faq-page">

                    <div className="container">
                        <div className="title-page">
                            <p className="title-route"><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa":"Главная"}</Link>    > <span className="mainColor">{uz?"Tanlovlar":"Конкурсы"}</span></p> <br/>

                        </div>
                        <div className="title-page">
                            <p className="big-title-route">
                                {uz?"Tanlovlar":"Конкурсы"}
                            </p>
                            <p className="calendar-icon">
                                {/*<FaCalendarAlt/>*/}
                            </p>
                        </div>
                        <Tabs onChange={callback} className={"tabs-panel"} defaultActiveKey="1">
                            <TabPane   tab={<h3 className={'tab-title title1'}>{uz?"Barchasi":"Все"}</h3>} key="1">
                                <All_contests loading={this.state.loading} contests={contests} MoreContests={MoreContests}/>
                            </TabPane>

                            {
                                contest_category?contest_category.map(item=>{
                                    return <TabPane tab={<h3 className={'tab-title'}>{uz?item.titleUz:item.titleRu}</h3>} key={item.id}>
                                        <All_contests loading={this.state.loading} contests={contests} MoreContests={MoreContests}/>
                                    </TabPane>

                                }):""
                            }


                        </Tabs>
                    </div>


                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Contests
)