import React from 'react'
import {
    Row,
    Col,
    Card
} from 'antd';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {getGallerys} from "../../../api/config/Servises";
import {host, port} from "../../../api/host";
import Loader from "../../../commonComponents/loader/loader";
const {Meta}=Card;
class Gallery extends React.Component{
    state={
        galleries:[],
        size:8,
        loading:true,
    }
    componentDidMount() {
        getGallerys(0,this.state.size).then(res=>{
            if(res&&res.data&&res.data.content){
                this.setState({
                    galleries:res.data.content,
                    loading:false,
                })
            }
        })

    }

    render() {
        const {uz,en}=this.props;
        const {galleries,size}=this.state;
        const MoreGallery=()=>{
            this.setState({
                size:size+8,
            })
            getGallerys(0,this.state.size).then(res=>{
                if(res&&res.data&&res.data.content){
                    this.setState({
                        galleries:res.data.content,
                    })
                }
            })
        }
        return(
            <>
                {
                    this.state.loading?<Loader/>:
                        <Row>
                            {
                                galleries?galleries.map(function (item){
                                    return <Col lg={6} md={12} sm  ={24}>
                                        <Link to={'/gallery/'+item.id}><Card
                                            data-aos={'zoom-in'}
                                            hoverable
                                            style={{ width: '90%',height:'auto',borderRadius:'15px',marginLeft:'5%',marginBottom:'50px' }}
                                            cover={<img alt="example" src={host+':'+port+'/api/auth/file/'+item.logoId} style={{borderTopLeftRadius:'15px',objectFit:'cover',borderTopRightRadius:'15px',height:'250px'}}/>}
                                        >
                                            <Meta title={''} description={<h5 style={{color:"grey",fontSize:'18px',textAlign:'center'}}> </h5>} />
                                            <p className='gallery_title' style={{fontSize:'16px',textAlign:'center',fontWeight:'600'}}>{uz?item.titleUz:item.titleRu} </p>
                                        </Card></Link>
                                    </Col>
                                }):""
                            }



                        </Row>
                }
                <div className="d-flex-center">
                    <button className="button mission-btn " onClick={MoreGallery}>
                        {uz?"Ko'proq ko'rsatish":"Показать еще"}
                    </button>
                </div>

            </>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Gallery
)