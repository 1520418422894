import React from 'react';
import {
    Row,
    Col,
} from 'antd';
import CarouselComponent from "./components/mainSlider/mainSlider";
import OurMission from "./components/ourMisson/ourMission";
import News from "./components/news/news";
import Grands from "./components/grands/grands";

import ConsultationCarousel from "./components/consultationCarousel/consultationCarousel";
import { getMainSliders } from '../../api/config/Servises';
import Loader from '../../commonComponents/loader/loader';
class HomePage extends React.Component{
    
    componentDidMount() {
    
    }

    render() {



        return(
            <>
                <div>
                   <Row>
                      <Col className={'p5'} md={24} sm={24}>
                          <CarouselComponent />
                      </Col>
                      <Col className={'p5'} md={24} sm={24}>
                          <OurMission />
                      </Col>
                      <Col className={'p5'} md={24} sm={24}>
                          <News />
                      </Col>
                      <Col className={'p5'} md={24} sm={24}>
                          <Grands />
                      </Col>
                      <Col className={'p5'} md={24} sm={24}>
                          <ConsultationCarousel />
                      </Col>
                  </Row>
              </div>
               

            </>
        )
    }

}
export default HomePage