import React from 'react';
import './ourMission.css';
import '../../../../styles/global.css'
import {
    Row,
    Col,
    Card,
} from 'antd';
import Slider from "react-slick";
import {
    RiUserStarLine,
    HiUserGroup,
    FaHandsHelping,
    BiSupport,
    ImInfo,
    IoFileTrayFullSharp


} from 'react-icons/all'
import {Link} from "react-router-dom";
import AOS from 'aos';
import {connect} from "react-redux";
import {uzLanguage} from "../../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../../redux/actions/EnLanguage";
import Fade from 'react-reveal/Fade';
class OurMission extends React.Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    componentDidMount() {
        AOS.init();
    }

    render() {
        const {uz,en}=this.props;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            // customPaging: bb => (
            //     <div
            //         onMouseDown={this.bb}
            //         className="consultation-dots"
            //
            //     >
            //     </div>
            // )
        };
        return(
            <>
                <div className="container ourmission-page">
                    <div className="page-title-content">
                        <div className="title-content">
                            <p className="home-page-title">
                                {uz?"Maqsadimiz":en?"":"наша миссия"}
                            </p>
                        </div>
                        <div className="title-content">
                            <span className="title-bottom-linear"></span>
                        </div>
                        <p className="mainColor little-text">
                            {uz?"Portalning asosiy maqsadi o‘qituvchilarni har tomonlama qo‘llab -quvvatlashdir":en?"":"Основной целью портала является оказание всесторонней поддержки учителям"}
                             </p>

                    </div>

                    <div className="card-page">
                         <Row>
                             <Col lg={12} md={12} sm={24} xs={24}>

                                     <div  data-aos="fade-right"  className="card-content">
                                         <div className={'icon-card'}>
                                      <span className="mission-icon">
                                          <RiUserStarLine style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span>
                                             <span className="icon-bottom-line"></span>
                                         </div>
                                         <div className={'text-card'}>
                                             <p className={'text-card-big-text'}>
                                                 {uz?"O‘qituvchilar maqomini ko‘tarish":en?"":"Повышение статуса учителей"}

                                             </p>
                                             <p className={'text-card-little-text'}>
                                                 {uz?"O‘qituvchilarning jamiyatdagi o‘rni va mavqeini oshirish":en?"":"Повышение роли и статуса учителя в обществе"}</p>
                                         </div>
                                     </div>

                             </Col>
                             <Col lg={12} md={12} sm={24} xs={24}>
                                 <div className="card-content" data-aos={'fade-left'}>
                                     <div className={'icon-card'}>
                                         <span className="mission-icon">
                                          <HiUserGroup style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span>
                                          <span className="icon-bottom-line"></span>
                                     </div>
                                     <div className={'text-card'}>
                                         <p className={'text-card-big-text'} >
                                             {uz?"Tarmoqni rivojlantirish":en?"":"Развитие нетворкинга"}
                                         </p>
                                         <p className={'text-card-little-text'}>
                                             {uz?"O‘qituvchilarning o‘zaro aloqasi uchun platforma":en?"":"Площадка для общения и взаимодействия учителей"}
                                         </p>
                                     </div>
                                 </div>
                             </Col>
                             <Col lg={12} md={12} sm={24} xs={24}>
                             <div className="card-content" data-aos={'fade-right'}>
                                 <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <FaHandsHelping style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span> <span className="icon-bottom-line"></span>
                                 </div>
                                 <div className={'text-card'}>
                                     <p className={'text-card-big-text'}>
                                         {uz?"O‘qituvchilarni o‘zaro qo‘llab - quvvatlash":"Взаимоподдержка учителей"}

                                     </p>
                                     <p className={'text-card-little-text'}>
                                         {uz?"\"O‘qituvchidan o‘qituvchiga\" tamoyili bo‘yicha yordam berish":" Помощь по принципу «От учителя к учителю»"}

                                     </p>
                                 </div>
                             </div>
                         </Col>
                             <Col lg={12} md={12} sm={24} xs={24}>
                             <div className="card-content" data-aos={'fade-left'}>
                                 <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <BiSupport style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span><span className="icon-bottom-line"></span>
                                 </div>
                                 <div className={'text-card'}>
                                     <p className={'text-card-big-text'}>

                                         {uz?"Konsultatsiya":"Консультация"}
                                     </p>
                                     <p className={'text-card-little-text'}>

                                         {uz?"O‘z vaqtida professional maslahat":"Своевременная профессиональная консультация"}
                                     </p>
                                 </div>
                             </div>
                         </Col>
                             <Col lg={12} md={12} sm={24} xs={24}>
                             <div className="card-content" data-aos={'fade-right'}>
                                 <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <ImInfo style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span><span className="icon-bottom-line"></span>
                                 </div>
                                 <div className={'text-card'}>
                                     <p className={'text-card-big-text'}>

                                         {uz?"Keng imkoniyatlar":"Информирование о возможностях"}
                                     </p>
                                     <p className={'text-card-little-text'}>
                                         {uz?"Ta’lim sohasidagi tanlovlar, grantlar va ko‘rgazmalar haqida to‘liq ma’lumot":" Полная информация о конкурсах, грантах и ярмарках в сфере образования"}

                                     </p>
                                 </div>
                             </div>
                         </Col>
                             <Col lg={12} md={12} sm={24} xs={24}>
                             <div className="card-content" data-aos={'fade-left'}>
                                 <div className={'icon-card'}>
                                     <span className="mission-icon">
                                          <IoFileTrayFullSharp style={{color:'rgba(0,0,0,0.7)'}}/>
                                      </span><span className="icon-bottom-line"></span>
                                 </div>
                                 <div className={'text-card'}>
                                     <p className={'text-card-big-text'}>

                                         {uz?"Resurslar bazasini yaratish":"Создание ресурсной базы"}
                                     </p>
                                     <p className={'text-card-little-text'}>
                                         {uz?"Elektron, video va uslubiy resurslarning keng ma’lumotlar bazasini yaratish":"Создание обширной базы электронных, видео и методических ресурсов"}

                                     </p>
                                 </div>
                             </div>
                         </Col>
                         </Row>
                        <div className="video" data-aos={'zoom-in'}>

                            <video controls className={'w100'}>
                                <source src="/videos/video_2021-05-31_13-01-21.mp4"/>
                            </video>

                        </div>

                         <div className="d-flex-center"> <Link to={'/about/mission'} >
                             <button className="button mission-btn" style={{marginTop:'30%'}}>

                                 {uz?"Batafsil ma’lumot":"Узнать больше"}
                             </button></Link>
                         </div>
                    </div>
                </div>
            </>
        )
    }

}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    OurMission
)
