import React from 'react';
import './forum.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Row,
    Col,
    Select,
    Button,
    Modal,
    Form,
    Input,
    message,
} from 'antd';
import '../../styles/global.css';
import {
    FaDiscourse,
}from 'react-icons/fa';
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {uzLanguage} from "../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../redux/actions/RuLanguage";
import {enLanguage} from "../../redux/actions/EnLanguage";
import {getMe} from "../../api/config/authentication";
import {deleteCookie} from "../../utils/useCookies";
import {userAccessTokenName} from "../../api/constants";
import { getForumCategoriesById, getForumsByCategory, postForum} from "../../api/config/Servises";
import {host, port} from "../../api/host";
const {Option}=Select;
class ForumByCategory extends React.Component {
    state={
        current_user:null,
        category:'',
        totalElements:0,
        forums:[],
        size:6,
        avatarId:null,
    }
    componentDidMount() {
        getForumCategoriesById(window.location.pathname.split('/')[2]).then(res=>{
            if (res&&res.data) {
                this.setState({
                    category:res.data,
                })
            }
        }).catch(error=>{
            console.log(error);
        })
        getMe().then(res => {
            if (res && res.data) {
                this.setState({
                    current_user: res.data,
                    avatarId:res.data.avatarId?res.data.avatarId:null,
                })
            } else {
                deleteCookie(userAccessTokenName)
            }
        }).catch(error=>{
            console.log(error);
        })
        getForumsByCategory(window.location.pathname.split('/')[2],0,6).then(res=>{
            if (res&&res.data) {
              
              this.setState({
                totalElements:res.data.totalElements,
                forums:res.data.content,
              })
            }
        }).catch(error=>{
            console.log(error);
        })

    }

    render() {
        const {uz,en}=this.props;
        const {current_user,isModalVisible,category,totalElements,forums,avatarId}=this.state;
        const PostForum=()=>{
            if(!current_user){
                toast.warn(uz?"Javob yuborish uchun ro'yxatdan o'tishingiz yoki tizimga kirishingiz kerak":"Вы должны зарегистрироваться или войти в систему, чтобы оставить ответ");
            }
            var forum={
                content:document.querySelector('.consultation-input').value,
                forumCategoryId:window.location.pathname.split('/')[2]
            }
            postForum(forum).then(res=>{
                if (res&&res.data) {
                    getForumsByCategory(window.location.pathname.split('/')[2],0,this.state.size).then(res=>{
                        if (res&&res.data) {
                          
                          this.setState({
                            totalElements:res.data.totalElements,
                            forums:res.data.content,
                          })
                        }
                    })
                    document.querySelector('.consultation-input').value='' 
                }
            }).catch(error=>{
                console.log(error);
            })
        }
        const getMoreForums=()=>{
            getForumsByCategory(window.location.pathname.split('/')[2],0,this.state.size+6).then(res=>{
                if (res&&res.data) {
                    this.setState({
                        totalElements:res.data.totalElements,
                        forums:res.data.content,
                        size:this.state.size+6,
                      })
                }
            })
        }

        return (
            <div style={{width:'100%',overflowX:'hidden'}}>
                <ToastContainer />
                <HorizontalMenu/>
                <div className="faq-page">
                    <div className="container">
                        <div className="title-page">
                            <p className="title-route" style={{width:'100%'}}><Link to={'/home'} style={{color:'black'}}>{uz?"Bosh sahifa ":"Главная"}</Link>  >  <span className="mainColor">Форум</span></p> <br/>
                        </div>
                        <h1>
                            {uz?category.titleUz:category.titleRu}
                        </h1>
                    </div>
                    <div className="questions-page">
                        <div className="page-title-lenta">
                            <div className="container">
                                <Row>

                                    <Col span={20}>
                                        <div className="" style={{fontSize:'22px'}}  className={"mainColor fs-fw"}>
                                        {uz?category.titleUz:category.titleRu}
                                        </div>
                                    </Col>
                                    <Col span={4}>
                                        <h3 className={"mainColor fs-fw"}>{totalElements} {uz?" ta savol":" вопрос"}</h3>
                                    </Col>

                                </Row>

                            </div>
                        </div>
                        <div className="container " style={{marginBottom:'20px'}}>
                           
                            {
                                forums?forums.map(item=>{
                                    return <div className="p10">
                                    <Row>
                                        <Col span={2}   className="p10">
                                            <div className="discuss discuss-color-before" style={{marginTop:'15px'}}>
                                                <FaDiscourse/>
                                            </div>
                                        </Col>
                                        <Col span={18}   className="p10">
                                        <Link to={'/questions/'+item.id}> <div className="question ">
                                                <p style={{color:'grey'}} className={'active-question'}>
                                                    {item.content}
                                                </p>
                                            </div></Link>
                                        </Col>
                                        <Col span={4}   className="p10">
                                            <h2>{item.replyCount}</h2>
                                            <h4>{uz?"xabarlar":"сообщений"}</h4>
                                        </Col>
    
                                    </Row>
                                </div>
                                }): <p className={' fs-fw d-flex-center p10'}>
                                {uz?"Bu bo'yicha savollar mavjud emas":"По этому поводу нет вопросов"}
                                 </p>

                            }
                         
                        
                        </div>

                    </div>

                    <div className="d-flex-center">
                      {totalElements>0? totalElements>6? <button className="button mission-btn news-btn"  onClick={getMoreForums}>
                            {uz?"Ko'proq ko'rsatish":"Показать еще"}
                        </button>:"":<p className={' fs-fw d-flex-center p10'}>
                                {uz?"Bu bo'yicha savollar mavjud emas":"По этому поводу нет вопросов"}
                                 </p>}
                    </div>
                </div>

                <div className="container">
                    <div className="p10">
                            <Row>
                                <Col span={4}  className="p10">
                                    <div className="user-avatar" style={{marginTop:'15px'}}>
                                        <img src={avatarId?host+':'+port+'/api/auth/file/'+avatarId:'/images/avatar.jpg'} alt="User"/>

                                    </div>
                                    <div className="d-flex-center">
                                        <p className="fullname" style={{color:'rgba(0,0,0,0.7)'}}>
                                        <span className="mainColor">
                                            Siz:
                                        </span>
                                        {current_user?this.state.current_user.firstname+" \n"+this.state.current_user.lastname:""}
                                            
                                        </p>
                                    </div>
                                </Col>
                                <Col span={20}  className="p10">
                                    <div style={{minHeight:'150px'}} className="title-area-forum consultation-input-area p10 consultation-textarea">
                                        <textarea  placeholder={uz?"Matn":"Tекст"} className="consultation-input consultation-textarea"/>
                                    </div>
                                    <button className="button mission-btn news-btn"  onClick={PostForum}>
                                        {uz?"Javob yuborish":"Ответить"}
                                    </button>
                                </Col>

                            </Row>
                        </div>
         
                    </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    ForumByCategory
)
