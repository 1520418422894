import React from 'react';
import './profile.css';
import {

    Row,
    Col,
    Card
} from 'antd';
import {
    FaUser,
    AiFillFileText,
    AiFillQuestionCircle,
    AiOutlineMessage,
    FaUserCircle
} from "react-icons/all";
import HorizontalMenu from "../../commonComponents/horizontalMenu/horizontalMenu";
import ProfileItem from "./components/profile";
import ConsultationItem from "./components/concultationitem";
import ForumItem from "./components/forumitem";
import ForumReply from "./components/forumreply";
import ConsultationById from "./components/consultationById";
import ForumById from "./components/forumById";
import { connect } from 'react-redux';



class Profile extends React.Component {
    state={
        tab:'profile',
        id:'',
    }
    render() {
        const {
            tab
        }=this.state;
        const {uz,en}=this.props;
        const SelectId=(id)=>{
            this.setState({
                id:id,
            })
        }
        const Select_tab=(key,item)=>{
            this.setState({
                tab:key,
                id:'',
            })
                document.querySelector('.item-1').classList.remove('active')
                document.querySelector('.item-2').classList.remove('active')
                document.querySelector('.item-3').classList.remove('active')
                document.querySelector('.item-4').classList.remove('active')
                document.querySelector(`.${item}`).classList.add('active')

        }
        return (
            <>
                <HorizontalMenu/>
                <Row>
                    <Col md={8} sm={24} xs={24}>
                        <div className="profile-menu">
                           <div className="menu-item item-1 active">
                             <Row>
                                 <Col span={3}>
                                     <FaUser style={{fontSize:'30px'}}/>
                                 </Col>
                                 <Col span={21}>
                                     <p className="profile-title" onClick={()=>Select_tab('profile','item-1')}>
                                     {uz?"Profil":"Профиль"}
                                     </p>
                                 </Col>
                             </Row>
                           </div>  <div className="menu-item item-2">
                            <Row>
                                <Col span={3}>
                                    <AiFillFileText style={{fontSize:'30px'}}/>
                                </Col>
                                <Col span={21}>
                                    <p className="profile-title" onClick={()=>Select_tab('consultation','item-2')}>
                                        
                                        {uz?"Maslahatlar":"Консультации"}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                            <div className="menu-item item-3">
                                <Row>
                                    <Col span={3}>
                                        <AiFillQuestionCircle style={{fontSize:'30px'}}/>
                                    </Col>
                                    <Col span={21}>
                                        <p className="profile-title" onClick={()=>Select_tab('forum','item-3')}>
                                            
                                            {uz?"Forum: Mening savollarim":"Форум: Мои вопросы"}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <div className="menu-item item-4">
                                <Row>
                                    <Col span={3}>
                                        <AiOutlineMessage style={{fontSize:'30px'}}/>
                                    </Col>
                                    <Col span={21}>
                                        <p className="profile-title" onClick={()=>Select_tab(' ','item-4')}>
                                            
                                            {uz?"Forum: Mening muhokamalarim":"Форум: Мои обсуждения"}
                                        </p>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </Col>
                    <Col md={16} sm={24} xs={24}>
                        {
                            tab==='profile'?<ProfileItem/>:tab==='consultation'?this.state.id===''?
                                <ConsultationItem SelectId={SelectId}/>:
                                <ConsultationById SelectId={SelectId}/>:tab==='forum'?
                                this.state.id===''?<ForumItem  SelectId={SelectId}/>:<ForumById SelectId={SelectId}/>:
                                this.state.id===''?<ForumReply SelectId={SelectId}/>:
                                    <ForumById  SelectId={SelectId}/>
                        }
                    </Col>
                </Row>



            </>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{})(
    Profile
)