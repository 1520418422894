import React from 'react';
import './new.css'

class HomeLoader extends  React.Component{
    render() {
        
        return (
        //     <div className='anime'>
        //     <div class="loader">
        //       <span></span>
        //       <span></span>
        //       <span></span>
        //       <span></span>
        //       <span></span>
        //     </div>
        //   </div>
       <div className='anime'>
            <div class="loader">
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__bar"></div>
  <div class="loader__ball"></div>
  <section>
  <div class="content">
    <h2>O'qituvchilar.Uz</h2>
    <h2>O'qituvchilar.Uz</h2>
  </div>
</section>
</div>
       </div>
        );
    }
}
export default HomeLoader