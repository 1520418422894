import React from 'react';
import './../resources.css';
import {
    Row,
    Col,
    Select,
} from 'antd';
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";
import {getUsefulLinks} from "../../../api/config/Servises";
import {host, port} from "../../../api/host";
import Loader from "../../../commonComponents/loader/loader";


const { Option } = Select;

class Line_Links extends React.Component {
    state={
        links:[],
        size:8,
        loading:true,
    };
    componentDidMount() {
        getUsefulLinks(0,this.state.size).then(res=>{
            if (res&&res.data&&res.data.content){
                this.setState({
                    links:res.data.content,
                    loading:false,
                })
            }
        })
    }
    render() {
        const {uz,en}=this.props;
        const {links,size}=this.state;
        const MoreLinks=()=>{
            this.setState({
                size:size+8,
            })
            getUsefulLinks(0,size).then(res=>{
                if (res&&res.data&&res.data.content){
                    this.setState({
                        links:res.data.content,
                    })
                }
            })
        }
        return (
            <>
                <div className="videos_page">
                    <div className={"select_area"}>
                        <div>
                            <Select defaultValue="lucy" style={{ width: 120 }} onChange={''}>
                            <Option value="jack">{uz?"Eng mashhur":"Популярные"}</Option>
                                <Option value="lucy">{uz?"Eng so'nggi":"Последние"}</Option>
                                <Option value="Yiminghe">{uz?"Eng yangi":"Новые"}</Option>


                            </Select>
                        </div>
                    </div>

                    {
                        this.state.loading?<Loader/>:  <Row>
                            {
                                links?links.map(item=>{
                                    return  <Col md={12} sm={12} xs={24}>
                                        <div className="videos-card"  data-aos={'zoom-in'}>
                                            <div className="w75">
                                                <Row>
                                                    <Col md={12} sm={24}>
                                                        <div className="img-and-video">
                                                            <img src={host+':'+port+'/api/auth/file/'+item.logo.id} width={'100%'} alt=""/>
                                                        </div>
                                                    </Col>
                                                    <Col md={12} sm={24}><p className={'video-title'} style={{textAlign:'center'}}>
                                                        {uz?item.titleUz.length>60?item.titleUz.slice(0,60)+'...':item.titleUz:item.titleRu.length>60?item.titleRu.slice(0,60)+'...':item.titleRu}
                                                    </p>
                                                        <p style={{color:'grey',textAlign:'center',fontSize:'18px',textAlign:'center'}}>
                                                            <a target={'_blank'} href={item.link}>{uz?"Saytga o‘tish":"Перейти на сайт"}</a>
                                                        </p></Col>
                                                </Row>

  
                                            </div>
                                        </div>
                                    </Col>
                                }):""
                            }


                        </Row>
                    }

                    <div className="d-flex-center">
                        <button className="button mission-btn news-btn" onClick={MoreLinks}>
                            {uz?"Ko'proq ko'rsatish":"Показать еще"}
                        </button>
                    </div>
                </div>
            </>
        )
    }

}



const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Line_Links
)