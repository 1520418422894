import React from 'react';
import './../resources.css';
import {
    Row,
    Col,
    Select,
} from 'antd';
import {connect} from "react-redux";
import {uzLanguage} from "../../../redux/actions/Uzlanguage";
import {ruLanguage} from "../../../redux/actions/RuLanguage";
import {enLanguage} from "../../../redux/actions/EnLanguage";


const { Option } = Select;

class Line_Video_lessons extends React.Component {
    render() {
        const {uz,en}=this.props;
        return (
            <>
                <div className="videos_page">
                    <div className={"select_area"}>
                        <div>
                            <Select defaultValue="lucy" style={{ width: 120 }} onChange={''} removeIcon={true}>
                            <Option value="jack">{uz?"Eng mashhur":"Популярные"}</Option>
                                <Option value="lucy">{uz?"Eng so'nggi":"Последние"}</Option>
                                <Option value="Yiminghe">{uz?"Eng yangi":"Новые"}</Option>


                            </Select>
                        </div>
                    </div>

                    <Row>
                        <Col md={12} sm={12} xs={24}>
                            <div className="videos-card">
                                <div className="w75">
                                    <Row>
                                        <Col md={12} sm={24}>
                                            <div className="img-and-video">
                                                <video controls className={'w100'}>
                                                    <source src="/videos/video1.mp4"/>
                                                </video>
                                            </div>
                                        </Col>
                                        <Col md={12} sm={24}>
                                            <p className={'video-title'}>
                                                Lorem ipsum dolor sit amet elit. Alias, quo!
                                            </p>
                                            <p style={{color:'grey'}}>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolorem ea eveniet harum inventore nisi quas qui rem ullam unde.
                                            </p>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <div className="videos-card">
                                <div className="w75">
                                    <Row>
                                        <Col md={12} sm={24}>
                                            <div className="img-and-video">
                                                <video controls className={'w100'}>
                                                    <source src="/videos/video1.mp4"/>
                                                </video>
                                            </div>
                                        </Col>
                                        <Col md={12} sm={24}>
                                            <p className={'video-title'}>
                                                Lorem ipsum dolor sit amet elit. Alias, quo!
                                            </p>
                                            <p style={{color:'grey'}}>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolorem ea eveniet harum inventore nisi quas qui rem ullam unde.
                                            </p>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <div className="videos-card">
                                <div className="w75">
                                    <Row>
                                        <Col md={12} sm={24}>
                                            <div className="img-and-video">
                                                <video controls className={'w100'}>
                                                    <source src="/videos/video1.mp4"/>
                                                </video>
                                            </div>
                                        </Col>
                                        <Col md={12} sm={24}>
                                            <p className={'video-title'}>
                                                Lorem ipsum dolor sit amet elit. Alias, quo!
                                            </p>
                                            <p style={{color:'grey'}}>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolorem ea eveniet harum inventore nisi quas qui rem ullam unde.
                                            </p>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                            <div className="videos-card">
                                <div className="w75">
                                    <Row>
                                        <Col md={12} sm={24}>
                                            <div className="img-and-video">
                                                <video controls className={'w100'}>
                                                    <source src="/videos/video1.mp4"/>
                                                </video>
                                            </div>
                                        </Col>
                                        <Col md={12} sm={24}>
                                            <p className={'video-title'}>
                                                Lorem ipsum dolor sit amet elit. Alias, quo!
                                            </p>
                                            <p style={{color:'grey'}}>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolorem ea eveniet harum inventore nisi quas qui rem ullam unde.
                                            </p>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="d-flex-center">
                        <button className="button mission-btn news-btn">
                            {uz?"Ko'proq ko'rsatish":"Показать еще"}
                        </button>
                    </div>
                </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {

    return {
        uz: state.changeLang.uz,
        en: state.changeLang.en,
    };
};
export default connect(mapStateToProps,{uzLanguage,ruLanguage,enLanguage})(
    Line_Video_lessons
)